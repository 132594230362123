import {
  Avatar,
  Card,
  Chip,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { formatTimestamp, formatTimestampFromNow } from '../../utils/datetime'
import { userCardStyles } from './UserCard@styles'
import { UserCardPropsTypes } from './UserCard@types'

const useStyles = makeStyles(userCardStyles)

const UserCard = (props: UserCardPropsTypes) => {
  // props
  const {
    fullName,
    profession,
    initials,
    lastLoginAt,
    createdAt,
    verified,
    coverUrl,
    roles,
  } = props

  // hooks
  const classes = useStyles({ coverUrl })
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <div className={classes.header}>
          <div className={classes.blurBg} />
        </div>

        {verified ? (
          <div className={classes.chip}>
            <Chip
              size="small"
              label={t`UserCard.Email_Is_Verified`}
              color="primary"
            />
          </div>
        ) : (
          <div className={classes.chip}>
            <Chip
              size="small"
              label={t`UserCard.Email_Is_Not_Verified`}
              color="secondary"
            />
          </div>
        )}

        <div className={classes.spacer}>
          <div className={classes.avatarWrapper}>
            <Avatar className={classes.avatar}>{initials}</Avatar>
          </div>
          <div className={classes.content}>
            <Typography variant="h6">{fullName}</Typography>
            <Typography variant="overline" display="block">
              {profession}
            </Typography>
            <div>
              {roles?.map((role: string, index: number) => (
                <Chip
                  key={index}
                  className={classes.roleChip}
                  size="small"
                  label={role}
                  variant="outlined"
                  color="primary"
                />
              ))}
            </div>
          </div>
        </div>
        <Divider />
        <div className={classes.spacer}>
          <div className={classes.footer}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Typography variant="caption" display="block" gutterBottom>
                  {t`UserCard.Last_Login`}
                </Typography>
                <Typography variant="subtitle1">
                  {formatTimestampFromNow(Number(lastLoginAt))}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" display="block" gutterBottom>
                  {t`UserCard.Date_Of_Registration`}
                </Typography>
                <Typography variant="subtitle1">
                  {formatTimestamp(Number(createdAt))}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default UserCard
