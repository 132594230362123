import { useCallback, useContext } from 'react'
import { Button, Dialog as DialogComponent, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';

import { DialogContext } from 'providers/dialog.provider'
import { useTranslation } from 'react-i18next';

interface IDialogProps {
  title: string
  contentText: string
  onConfirm: () => void
  confirmText?: string
  cancelText?: string
  variant?: 'info' | 'warning' | 'error'
}

const Dialog = (props: IDialogProps) => {
  const { title, contentText, onConfirm, confirmText, cancelText, variant } = props
  const { dialog, handleDialogAction } = useContext(DialogContext)

  const { t } = useTranslation()

  const dialogType = useCallback(() => {
    if (variant === 'error') {
      return 'error'
    } else {
      return 'primary'
    }
  }, [variant])

  const handleConfirm = useCallback(() => {
    onConfirm()
    handleDialogAction()
  }, [onConfirm, handleDialogAction])

  return (
    <DialogComponent
      open={dialog}
      onClose={handleDialogAction}
    >
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {contentText}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogAction}>
          <Typography color='inherit'>
            {cancelText ? t`${cancelText}` : t`Global.Cancel`}
          </Typography>
        </Button>
        <Button autoFocus onClick={handleConfirm}>
          <Typography color={dialogType()}>
            {confirmText ? t`${confirmText}` : t`Global.Agree`}
          </Typography>
        </Button>
      </DialogActions>
    </DialogComponent>
  )
}

export default Dialog