import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FoodForm } from 'components/Forms';
import { useSnackbar } from 'notistack';
import { FoodMenuContext } from 'providers/foodMenu.provider';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { insertMenuStyles } from './FoodMenu@styles';

const useStyles = makeStyles(insertMenuStyles)

const InsertFood = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams<{ id: string }>();
  const { insertNewFoodToCategory } = useContext(FoodMenuContext)

  const onSubmit = useCallback((data) => {
    insertNewFoodToCategory(data, id)
    enqueueSnackbar(
      t`Alert.Item_Was_Added`,
      { variant: 'success' }
    )
  }, [insertNewFoodToCategory, enqueueSnackbar, t, id])

  return (
    <div className={classes.root}>
      <Typography gutterBottom variant='h4'>{t`Food_Menu.Add_Item`}</Typography>

      <FoodForm onSubmit={onSubmit} />
    </div>
  )
}

export default InsertFood