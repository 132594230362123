import { createStyles, Theme } from '@material-ui/core'

export const TableStyles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
      height: 'auto',
      maxHeight: '100%',
    },
    toolbar: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
    },
    basic: {
      width: '100%',
    },
    suplement: {
      display: 'flex',
      alignItems: 'center',
      paddingRight: theme.spacing(1),
    },
    tableSettingsContent: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
    tableSettingsContentCard: {
      padding: theme.spacing(2),
    },
  })

export const TableCheckIconStyles = (theme: Theme) =>
  createStyles({
    greenIcon: {
      color: 'green',
    },
    redIcon: {
      color: 'red',
    },
  })
