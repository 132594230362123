import { Theme, createStyles } from '@material-ui/core'

export const rootStyles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    alert: {
      zIndex: 1202,
      position: 'absolute',
      top: 10,
      right: 10,
      width: '100%',
      maxWidth: 320,
    },
  })
