import { createContext, ReactNode, useState } from 'react'
import firebase from '../remote/Firebase'
import { UserData } from '../types/user.types'

type Context = {
  userData: any
  updateProfileData: (data: UserData) => void
  loadUserData: () => void
  verifyEmailAddress: () => void
  getFirstLetterOfUsername: string
}

type UserProviderPropsTypes = {
  children: ReactNode
}

export const UserContext = createContext<Context>(null as any)

const UserProvider = (props: UserProviderPropsTypes) => {
  // state
  const [userData, setUserData] = useState<any>(null)

  // props
  const { children } = props

  /**
   * Load information about currect user
   * @returns
   */
  const loadUserData = async () => {
    const user = await firebase.auth().currentUser?.toJSON()
    await setUserData(user)
    return user
  }

  /**
   * Update user profile data
   * @param data
   */
  const updateProfileData = async (data: UserData) => {
    const user: any = await firebase.auth().currentUser

    await user?.updateProfile({
      ...data,
    })
  }

  /**
   *
   * @returns Send verification email on current user email
   */
  const verifyEmailAddress = async () => {
    const user: any = await firebase.auth().currentUser

    return user.sendEmailVerification()
  }

  const getFirstLetterOfUsername = userData?.displayName?.charAt(0)

  return (
    <UserContext.Provider
      value={{
        userData,
        updateProfileData,
        loadUserData,
        verifyEmailAddress,
        getFirstLetterOfUsername,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
