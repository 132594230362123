import { Theme, createStyles } from '@material-ui/core'

export const alertStyles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      margin: theme.spacing(1),
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      boxShadow: theme.shadows[1],
      borderRadius: theme.shape.borderRadius,
      borderLeft: '4px solid',
    },
    icon: {
      marginRight: theme.spacing(2),
      alignContent: 'center',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    close: {
      position: 'absolute',
      top: 4,
      right: 4,
    },
    title: {
      fontSize: theme.typography.pxToRem(14),
      marginBottom: theme.spacing(1),
    },
    description: {
      fontSize: theme.typography.pxToRem(14),
    },
    warning: {
      backgroundColor: '#fff4e5',
      color: '#663c00',
      borderColor: '#663c00',
    },
    error: {
      backgroundColor: '#fdecea',
      color: '#611a15',
      borderColor: '#611a15',
    },
    info: {
      backgroundColor: '#e8f4fd',
      color: '#0d3c61',
      borderColor: '#0d3c61',
    },
    success: {
      backgroundColor: '#edf7ed',
      color: '#1e4620',
      borderColor: '#1e4620',
    },
  })
