import { useState } from 'react'
import { createContext, ReactNode } from 'react'
import { IAlert } from '../types/alerts.types'

type Context = {
  alertsList: IAlert[] | []
  setAlertsList: any
  setAlert: ({ title, description, type }: IAlert) => void
  getAlerts: IAlert[] | []
  removeAlert: (id: string) => void
  alertTimeout: number
}

type AlertsProviderProps = {
  children: ReactNode
}

export const AlertsContext = createContext<Context>(null as any)

const AlertsProvider = (props: AlertsProviderProps) => {
  // state
  const [alertsList, setAlertsList] = useState<IAlert[] | []>([])

  // settings
  const alertTimeout = 5000

  // props
  const { children } = props

  /**
   * Set new alert
   * @param title
   * @param description
   * @param type
   */
  const setAlert = ({ id, title, description, type }: IAlert) => {
    const alert = {
      id,
      title,
      description,
      type,
    }
    setAlertsList([...alertsList, alert])
  }

  /**
   * Get all alerts
   * @returns all alerts in list
   */
  const getAlerts = alertsList

  /**
   * Remove alert from list on id of alert
   * @param id
   */
  const removeAlert = (id: string) => {
    const filteredAlerts: IAlert[] | [] = alertsList.filter((alert) => {
      return alert.id !== id
    })

    setAlertsList([...filteredAlerts])
  }

  return (
    <AlertsContext.Provider
      value={{
        alertsList,
        setAlertsList,
        setAlert,
        getAlerts,
        removeAlert,
        alertTimeout,
      }}
    >
      {children}
    </AlertsContext.Provider>
  )
}

export default AlertsProvider
