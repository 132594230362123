import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { CategoryForm } from 'components/Forms';
import { useSnackbar } from 'notistack';
import { DataContext } from 'providers/data.provider';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Collections, Documents } from 'remote/Collections';
import { insertCategoryStyles } from './FoodMenu@styles';

const useStyles = makeStyles(insertCategoryStyles)

const InsertCategory = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  // context
  const { insert } = useContext(DataContext)

  // remote settings
  const collection = Collections.Food_Menu
  const documents = Documents.Categories

  const onSubmit = useCallback((data) => {
    insert({ collection, documents, data })
    enqueueSnackbar(
      t`Alert.Category_Was_Added`,
      { variant: 'success' }
    )
  }, [insert, enqueueSnackbar, t, collection, documents])

  return (
    <div className={classes.root}>
      <Typography gutterBottom variant='h4'>{t`Food_Menu.Add_Category`}</Typography>

      <CategoryForm onSubmit={onSubmit} />
    </div>
  )
}

export default InsertCategory