import { makeStyles, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { app } from "configs"
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { General, Profile, Notifications, ChangePassword, SocialNetworks } from './components'
import { settingsStyles } from './Settings@styles'

const useStyles = makeStyles(settingsStyles)

const Settings = () => {
  // state
  const [value, setValue] = useState<string>('general')

  const handleChangeTab = (event: ChangeEvent<{}>, newValue: string) => {
    setValue(newValue)
  }

  // hooks
  const classes = useStyles()
  const { t } = useTranslation()

  const tabsList = [
    {
      label: t`Settings.General`,
      value: 'general',
      canUse: app.canUseGeneral,
    },
    {
      label: t`Settings.Profile`,
      value: 'profile',
      canUse: app.canUseProfile
    },
    {
      label: t`Settings.Notifications`,
      value: 'notifications',
      canUse: app.canUseNotifications
    },
    {
      label: t`Settings.Change_Password`,
      value: 'change-password',
      canUse: app.canUseChangePassword
    },
    {
      label: t`Settings.Social_Links`,
      value: 'social-links',
      canUse: app.canUseSocialLinks
    },
  ]

  const tabPanels = [
    {
      value: 'general',
      content: <General />
    },
    {
      value: 'profile',
      content: <Profile />
    },
    {
      value: 'notifications',
      content: <Notifications />
    },
    {
      value: 'change-password',
      content: <ChangePassword />
    },
    {
      value: 'social-links',
      content: <SocialNetworks />
    },
  ]

  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <TabList onChange={handleChangeTab} textColor="primary" indicatorColor="primary" aria-label="simple tabs example">
          {tabsList.map((tab, index) => (
            <Tab
              disabled={!tab.canUse}
              label={tab.label}
              key={index}
              value={tab.value}
            />
          ))}
        </TabList>

        {tabPanels.map((tabPanel, index) => (
          <TabPanel
            color="primary"
            key={index}
            value={tabPanel.value}
            className={classes.tabPanel}
          >
            {tabPanel.content}
          </TabPanel>
        ))}
      </TabContext>
    </div>
  )
}

export default Settings
