import { createStyles, Theme } from '@material-ui/core'

export const openingStyles = (theme: Theme) =>
  createStyles({
    root: {},
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      height: 'auto',
      border: '1px solid',
      borderColor: 'transparent',
    },
    right: {
      textAlign: 'right'
    }
  })
