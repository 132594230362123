import { IconButton } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Drawer from '@material-ui/core/Drawer'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { app } from "configs"
import { useContext, useEffect } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { AppContext } from '../../providers/app.provider'
import { UserContext } from '../../providers/user.provider'
import { Themes } from '../../styles/Styles@types'
import { Avatar } from '../Avatar'
import { Notifications } from '../Notifications'
import { DrawerContent } from './Layout@parts'
import { LayoutStyles } from './Layout@styles'
import { LayoutProps } from './Layout@types'

const useStyles = makeStyles(LayoutStyles)

const Layout = (props: LayoutProps) => {
  // props
  const { children } = props

  // context
  const { navOpen, setNavOpen, getSiderNavWidth, theme } =
    useContext(AppContext)

  // hooks
  const classes = useStyles({ getSiderNavWidth })

  // context
  const { userData, loadUserData, getFirstLetterOfUsername } =
    useContext(UserContext)

  // effect
  useEffect(() => {
    loadUserData()
    // eslint-disable-next-line
  }, [])

  const logoUrl = theme === Themes.light ? 'black' : 'white'

  const handleDrawerAction = () => {
    setNavOpen(!navOpen)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <AppBar
        position="absolute"
        className={clsx(
          classes.appBar,
          navOpen && !isMobile && !isTablet ? classes.appBarShift : null,
        )}
      >
        <Toolbar className={classes.toolbar}>
          <div className={classes.menuButtonWrapper}>
            <IconButton
              edge="start"
              aria-label="open drawer"
              onClick={handleDrawerAction}
              className={clsx(
                classes.menuButton,
                navOpen && !isMobile && !isTablet
                  ? classes.menuButtonHidden
                  : null,
              )}
            >
              <MenuIcon />
            </IconButton>
          </div>
          
          {app.canUseNotifications && <Notifications />}

          <Avatar />
        </Toolbar>
      </AppBar>

      {(isMobile || isTablet) && (
        <Drawer
          anchor="left"
          onClose={handleDrawerAction}
          onClick={handleDrawerAction}
          open={!navOpen}
          classes={{
            paper: clsx(classes.drawerPaper),
          }}
        >
          <DrawerContent
            getSiderNavWidth={getSiderNavWidth}
            logoUrl={logoUrl}
            getFirstLetterOfUsername={getFirstLetterOfUsername}
            handleDrawerAction={handleDrawerAction}
            userData={userData}
          />
        </Drawer>
      )}

      {!isMobile && !isTablet && (
        <Drawer
          onClose={handleDrawerAction}
          open={!navOpen}
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper),
          }}
        >
          <DrawerContent
            getSiderNavWidth={getSiderNavWidth}
            logoUrl={logoUrl}
            getFirstLetterOfUsername={getFirstLetterOfUsername}
            handleDrawerAction={handleDrawerAction}
            userData={userData}
          />
        </Drawer>
      )}

      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <div>{children}</div>
        </Container>
      </main>
    </div>
  )
}

export default Layout
