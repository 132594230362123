import { makeStyles } from '@material-ui/styles'
import { Button, Chip, Grid } from '@material-ui/core'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../../components/Table'
import { Label } from '../../components/Label'
import { DataContext } from '../../providers/data.provider'
import { Collections, Documents } from '../../remote/Collections'
import { OrdersStyles } from './Orders@styles'
import { OrdersStateType } from './Orders@type'
import { formatTimestamp } from '../../utils/datetime'
import { config } from '../../configs'

const useStyles = makeStyles(OrdersStyles)

const Orders = () => {
  // init
  const collection = Collections.WebManagement
  const documents = Documents.Orders

  // state
  const [state, setState] = useState<OrdersStateType>({ loading: true })
  const { loading } = state

  // hooks
  const { t } = useTranslation()
  const classes = useStyles()

  // context
  const { get, data } = useContext(DataContext)

  const columns = [
    {
      title: t`Orders.Name`,
      field: 'name',
      render: (rowData: any) => (
        <span>{`${rowData.name} ${rowData.surname}`}</span>
      ),
    },
    {
      title: t`Orders.Variant`,
      field: 'webVariant',
      render: (rowData: any) => (
        <Chip color="primary" size="small" label={rowData.webVariant} />
      ),
    },
    {
      title: t`Orders.Email`,
      field: 'email',
      render: (rowData: any) => (
        <Button
          color="primary"
          size="small"
          variant="text"
          href={`mailto:${rowData.email}`}
        >
          {rowData.email}
        </Button>
      ),
    },
    {
      title: t`Orders.Phone`,
      field: 'phone',
      render: (rowData: any) => (
        <Button
          color="primary"
          size="small"
          variant="text"
          href={`tel:${rowData.phone}`}
        >
          {rowData.phone}
        </Button>
      ),
    },
    {
      title: t`Orders.Date_Order`,
      field: 'createdAt',
      render: (rowData: any) => (
        <span>
          {formatTimestamp(
            rowData.createdAt,
            config.datetime.format.long_short,
          )}
        </span>
      ),
    },
  ]

  useEffect(() => {
    const refresh = async () => {
      await get({ collection, documents })
      await setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }))
      }, 100)
    }

    refresh()
    // eslint-disable-next-line
  }, [collection, documents])

  return (
    <div className={classes.root}>
      <Table
        title={t`Orders.Header`}
        columns={columns}
        data={data}
        isLoading={loading}
        detailPanel={(rowData: any) => {
          return (
            <div className={classes.tableDetailExpander}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <Label label={t`Orders.Company_Ico`} value={rowData.companyIco} />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Label label={t`Orders.City`} value={rowData.city} />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Label label={t`Orders.Domain`} checker={rowData.hasDomain} />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Label label={t`Orders.Hosting`} checker={rowData.hasHosting} />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <Label label={t`Orders.Company`} checker={rowData.companyName} />
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Label label={t`Orders.Message`} value={rowData.message} />
                </Grid>
              </Grid>
            </div>
          )
        }}
      />
    </div>
  )
}

export default Orders
