import { Grid, makeStyles, Paper, TextField } from '@material-ui/core'
import { Form, Formik, useFormik } from 'formik'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../components/Button'
import { Title } from '../../components/Title'
import { AuthContext } from '../../providers/auth.provider'
import { createFormUserFormChangePasswordSchema } from './UserProfile@config'
import { UserProfileStyles } from './UserProfile@styles'

const useStyles = makeStyles(UserProfileStyles)

const UserProfile = () => {
  const [state, setState] = useState({
    passwordNotMatch: false,
  })

  // context
  const { setNewPassword } = useContext(AuthContext)

  // hooks
  const { t } = useTranslation()
  const classes = useStyles()
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: createFormUserFormChangePasswordSchema(),
    onSubmit: () => handleChangePassword(),
  })

  const handleChangePassword = () => {
    if (formik.values.newPassword !== formik.values.confirmNewPassword) {
      return setState((prevState) => ({
        ...prevState,
        passwordNotMatch: true,
      }))
    }

    try {
      setNewPassword({ newPassword: formik.values.newPassword })
    } catch (err) {
      console.error(err)
    } finally {
      setState((prevState) => ({
        ...prevState,
        passwordNotMatch: false,
      }))
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Title>{t`User.Change_Password`}</Title>
            <Formik
              initialValues={formik.initialValues}
              onSubmit={() => formik.handleSubmit()}
            >
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      variant="filled"
                      margin="normal"
                      id="newPassword"
                      name="newPassword"
                      type="password"
                      label={t`User.New_Password`}
                      value={formik.values.newPassword}
                      onChange={formik.handleChange}
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      variant="filled"
                      margin="normal"
                      id="confirmNewPassword"
                      name="confirmNewPassword"
                      type="password"
                      label={t`User.Confirm_New_Password`}
                      value={formik.values.confirmNewPassword}
                      onChange={formik.handleChange}
                      autoComplete="new-password"
                    />
                  </Grid>
                  {state.passwordNotMatch && <p>Hesla se neshoduji</p>}
                  <Grid item xs={12}>
                    <Button color="primary" variant="contained" type="submit">
                      {t`User.Send`}
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Title>{t`User.User_Information`}</Title>
            <Button>Test</Button>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default UserProfile
