import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next';

import { IconEmpty } from 'components/Icons'
import { emptyDataStyles } from './EmptyData@styles'
import { IEmptyDataProps } from './EmptyData@types'

const useStyles = makeStyles(emptyDataStyles)

const EmptyData = (props: IEmptyDataProps) => {
  const { children } = props

  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <IconEmpty />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" color="primary" gutterBottom>
            {t`Global.Empty_Data`}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t`Global.Empty_Data_Subtitle`}
          </Typography>
        </Grid>
        {children && <Grid item xs={12}>
          {children}
        </Grid>}
      </Grid>
    </div>
  )
}

export default EmptyData