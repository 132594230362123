import { makeStyles, Typography } from '@material-ui/core'
import { CategoryForm } from 'components/Forms'
import { Spinner } from 'components/Spinner'
import { RouteName } from 'enums/RouteName'
import useRoute from 'hooks/useRoute'
import { useSnackbar } from 'notistack'
import { DataContext } from 'providers/data.provider'
import { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Collections, Documents } from 'remote/Collections'
import { editCategoryStyles } from './FoodMenu@styles'

const useStyles = makeStyles(editCategoryStyles)

const EditCategory = () => {
  const classes = useStyles()
  const route = useRoute()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams<{ id: string }>()

  // context
  const { update, getRecord, isLoading, record } = useContext(DataContext)

  // remote settings
  const collection = Collections.Food_Menu
  const documents = Documents.Categories

  const onSubmit = useCallback((data) => {
    update({ collection, documents, id, data })
    enqueueSnackbar(
      t`Alert.Category_Was_Updated`,
      { variant: 'info' }
    )
    route.push(RouteName.FoodMenu)
  }, [collection, documents, id, update, enqueueSnackbar, t, route])

  useEffect(() => {
    const refresh = () => {
      return getRecord({ collection, documents, id })
    }

    refresh()
    // eslint-disable-next-line
  }, [id])

  return (
    <div className={classes.root}>
      <Typography gutterBottom variant='h4'>
        {t`Food_Menu.Edit_Category`}
      </Typography>

      {
        isLoading
          ? <Spinner />
          : record && (
            <CategoryForm
              onSubmit={onSubmit}
              categoryDetail={record}
            />
          )
      }
    </div>
  )
}

export default EditCategory