import { FormControlLabel, Grid, Paper, Switch, TextField, Typography, makeStyles } from '@material-ui/core'
import { ChangeEvent, useCallback, useContext, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { DataContext } from 'providers/data.provider'
import { Collections } from 'remote/Collections'
import { openingStyles } from './Opening@styles'
import { IOpeningProps, IOpeningState } from './Opening@types'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles(openingStyles)

const Opening = ({ title, day, place }: IOpeningProps) => {
  const [state, setState] = useState<IOpeningState>({
    isEdited: false,
    isActive: true,
    fromTime: '',
    toTime: '',
    breakFromTime: '',
    breakToTime: '',
  })

  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { Opening_Hours } = Collections
  const { insert } = useContext(DataContext)

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setState((state) => ({
      ...state,
      isEdited: true,
      [event.target.id]: event.target.value,
    }))
  }, [])

  const handleSubmit = useCallback(() => {
    insert({ collection: Opening_Hours, documents: day, subCollection: place, data: state })
    enqueueSnackbar(t`Alert.Opening_Hours_Updated`, { variant: 'success' })
  }, [state, Opening_Hours, day, insert, place, t, enqueueSnackbar])

  const handleSwitch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setState((state) => ({
      ...state,
      isEdited: true,
      [event.target.name]: event.target.checked,
    }))
  }, [])

  return (
    <Grid item xs={12} md={4}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom component="div">
              <Trans i18nKey={title} />
            </Typography>
          </Grid>
          <Grid item xs={6} className={classes.right}>
            <FormControlLabel
              control={<Switch onChange={handleSwitch} checked={state.isActive} name="isActive" color="primary" />}
              label={t`Global.Active`}
              labelPlacement="start"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="fromTime"
              name="fromTime"
              label={t`Opening_Hours.From`}
              type="time"
              variant="outlined"
              onChange={handleChange}
              disabled={!state.isActive}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="toTime"
              name="toTime"
              label={t`Opening_Hours.To`}
              type="time"
              variant="outlined"
              onChange={handleChange}
              disabled={!state.isActive}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="subtitle1" gutterBottom component="div">
              {t`Opening_Hours.Break`}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="breakFromTime"
              name="breakFromTime"
              label={t`Opening_Hours.From`}
              type="time"
              variant="outlined"
              onChange={handleChange}
              disabled={!state.isActive}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="breakToTime"
              name="breakToTime"
              label={t`Opening_Hours.To`}
              type="time"
              variant="outlined"
              onChange={handleChange}
              disabled={!state.isActive}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 300,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button onClick={handleSubmit} fullWidth variant="outlined" color="primary" disabled={!state.isEdited}>
              {t`Global.Save`}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default Opening
