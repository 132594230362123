import { createStyles, Theme } from '@material-ui/core'
import { Colors } from '../../theme/Palette'

export const dailyMenuStyles = (theme: Theme) =>
  createStyles({
    root: {},
    tabPanel: {
      padding: theme.spacing(3, 0),
    },
  })

export const partsStyles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      height: 'auto',
      border: '1px solid',
      borderColor: 'transparent',
    },
    activated: {
      border: '2px solid',
      borderColor: Colors.primary.main,
    },
    spacing: {
      marginTop: theme.spacing(2),
    },
    saveButton: {
      position: 'fixed',
      bottom: 20,
      right: 20,
      zIndex: 10,
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',
    },
    skretch: {
      display: 'flex',
      alignItems: 'skretch',
    },
    left: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      flexWrap: 'wrap',
    },
    right: {
      textAlign: 'right',
    },
    lowercase: {
      textTransform: 'lowercase',
    },
  })
