import { makeStyles } from "@material-ui/styles"
import { flagStyles } from "./Flag@styles"
import { FlagPropsTypes } from "./Flag@types"

const useStyles = makeStyles(flagStyles)

function Flag(props: FlagPropsTypes) {
  // props
  const { code, format = 'svg', width = 24, height, spacing = 0 } = props

  // hooks
  const classes = useStyles()

  return (
    <div
      className={classes.root}
      style={{
        width: width || undefined,
        height: height || width || undefined,
        marginRight: spacing,
        backgroundImage: `url(/flags/${format}/${code.toUpperCase()}.${format})`,
      }}
    />
  )
}

export default Flag
