import { Theme, createStyles } from '@material-ui/core'
import { UserCardStylesPropsTypes } from './UserCard@types'

export const userCardStyles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    chip: {
      position: 'absolute',
      top: 20,
      right: 20,
    },
    card: {},
    header: (props: UserCardStylesPropsTypes) => ({
      backgroundImage: `url(${props.coverUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100% 190px',
      width: '100%',
      height: 190,
    }),
    blurBg: {
      backgroundColor: 'rgba(0, 82, 73, 0.52)',
      backdropFilter: 'blur(3px)',
      width: '100%',
      height: 190,
    },
    spacer: {
      padding: theme.spacing(4),
    },
    avatarWrapper: {
      position: 'absolute',
      top: 146,
      left: 'calc(50% - 43px)',
      borderRadius: '50%',
      border: '4px solid',
      borderColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5]
    },
    avatar: {
      width: theme.spacing(10),
      height: theme.spacing(10),
    },
    roleChip: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    content: {
      textAlign: 'center',
      marginTop: theme.spacing(3),
    },
    footer: {
      textAlign: 'center',
    },
  })
