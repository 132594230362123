import { Paper as PaperComponent, makeStyles } from '@material-ui/core';
import { ReactNode } from "react";
import { paperStyles } from './Paper@styles';

interface IPaperProps {
  children: ReactNode
}

const useStyles = makeStyles(paperStyles)

const Paper = (props: IPaperProps) => {
  const { children } = props
  
  const classes = useStyles()

  return (
    <PaperComponent className={classes.root}>
      {children}
    </PaperComponent>
  )
}

export default Paper