export enum RouteName {
  // Root
  // Dashboard = '/',
  HelpCenter = '/help-center',

  // Management
  Users = '/users',
  Contacts = '/contacts',
  Tasks = '/tasks',

  // Web management
  Orders = '/orders',
  Domains = '/domains',
  Question = '/questions',

  // Gastronomy
  DailyMenu = '/daily-menu',
  FoodMenu = '/food-menu',
  FoodMenu_Category = '/food-menu/category',
  FoodMenu_Category_Id = '/food-menu/category/:id',
  FoodMenu_InsertCategory = '/food-menu/insert-category',
  FoodMenu_EditCategory = '/food-menu/edit-category',
  FoodMenu_EditCategory_Id = '/food-menu/edit-category/:id',
  FoodMenu_InsertFood = '/food-menu/insert-food',
  FoodMenu_InsertFood_Id = '/food-menu/insert-food/:id',
  FoodMenu_EditFood = '/food-menu/edit-food',
  FoodMenu_EditFood_Id = '/food-menu/edit-food/:categoryId/:foodId',
  OpeningHours = '/opening-hours',
  GastroEnums = '/gastro-enums',

  // App
  About = '/about',

  // Auth
  Login = '/login',

  // User
  UserProfile = '/user-profile',
  Settings = '/settings',
}
