import { createStyles, Theme } from '@material-ui/core'

export const CategoryStyles = (theme: Theme) =>
  createStyles({
    root: {},
    category: {
      '&:hover': {
        boxShadow: theme.shadows[6],
      },
    },
    pointer: {
      cursor: 'pointer',
    },
  })
