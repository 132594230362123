import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from '../../components/Table';
import { config } from '../../configs';
import { QuestionsModel } from "../../models/questions.model";
import { DataContext } from "../../providers/data.provider";
import { Collections, Documents } from "../../remote/Collections";
import { formatLongerTimestamp } from "../../utils/datetime";
import { QuestionsStyles } from "./Questions@styles";
import { QuestionsStateTypes } from "./Questions@types";

const useStyles = makeStyles(QuestionsStyles)

const Questions = () => {
  // state
  const [state, setState] = useState<QuestionsStateTypes>({
    loading: false,
    noteDialog: false,
    rowData: undefined
  })
  const { loading, noteDialog, rowData } = state

  // init
  const collection = Collections.WebManagement
  const documents = Documents.Questions

  // hooks
  const classes = useStyles()
  const { t } = useTranslation()

  // context
  const { get, update, data, remove } = useContext(DataContext)

  const handleNoteDialogActions = (rowData?: QuestionsModel) => {
    if (noteDialog) {
      setState((prevState) => ({
        ...prevState,
        noteDialog: false,
        rowData: undefined
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        noteDialog: true,
        rowData: rowData
      }))
    }
  }

  const columns = [
    {
      title: t`Questions.Name`,
      field: 'name',
      render: (rowData: any) => (
        <span>{`${rowData.name} ${rowData.surname}`}</span>
      )
    },
    {
      title: t`Questions.Email`,
      field: 'email',
    },
    {
      title: t`Questions.Phone`,
      field: 'phone',
    },
    {
      title: t`Questions.Message`,
      field: 'message',
      render: (rowData: any) => (
        <Button
          onClick={() => handleNoteDialogActions(rowData)}
          color="primary"
          size="small"
        >
          {t`Global.Show`}
        </Button>
      )
    },
    {
      title: t`Questions.State`,
      field: 'hasSolved',
      lookup: {
        true: t`Questions.Solved`,
        false: t`Questions.Is_Not_Solved`,
      },
    },
    {
      title: t`Questions.Delivered`,
      field: 'createdAt',
      render: (rowData: any) => (
        <span>{formatLongerTimestamp(rowData.createdAt, config.datetime.format.long)}</span>
      )
    },
  ]

  useEffect(() => {
    const refresh = async () => {
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }))
      await get({ collection, documents })
      await setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }))
      }, 100)
    }

    refresh()
    // eslint-disable-next-line
  }, [collection, documents])

  return (
    <div className={classes.root}>
      <Table
        data={data}
        columns={columns}
        title={t`Questions.Header`}
        isLoading={loading}
        editable={{
          onRowDelete: async (oldData: any) =>
            new Promise(async (resolve, reject) => {
              await remove({ collection, documents, id: oldData.id })
              resolve(null)
            }),
          onRowUpdate: (newData: any) =>
            new Promise(async (resolve, reject) => {
              await update({ collection, documents, id: newData.id, data: newData })
              resolve(null)
            })
        }}
      />

      <Dialog fullWidth maxWidth="sm" onClose={() => handleNoteDialogActions()} open={noteDialog}>
        <DialogTitle id="message-dialog">
          {t`Questions.Message`}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {rowData ? rowData?.message : ''}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => handleNoteDialogActions()} color="primary">
            {t`Global.Close`}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Questions
