import {
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import SettingsIcon from '@material-ui/icons/Settings'
import { makeStyles } from '@material-ui/styles'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouteName } from '../../enums/RouteName'
import useRoute from '../../hooks/useRoute'
import { AuthContext } from '../../providers/auth.provider'
import { UserContext } from '../../providers/user.provider'
import { AvatarStyles } from './Avatar@styles'
import { AvatarState } from './Avatar@types'

const useStyles = makeStyles(AvatarStyles)

const Avatar = () => {
  // state
  const [state, setState] = useState<AvatarState>({
    menu: null,
  })

  // hooks
  const classes = useStyles()
  const route = useRoute()
  const { t } = useTranslation()

  // context
  const { logout } = useContext(AuthContext)
  const { userData, loadUserData } = useContext(UserContext)

  const handleShowMenu = useCallback((e: any) => {
    setState((prevState) => ({
      ...prevState,
      menu: e.currentTarget,
    }))
  }, [])

  const handleCloseMenu = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      menu: null,
    }))
  }, [])

  const handleSetRoute = useCallback((routeName: string) => {
    route.push(routeName)
    handleCloseMenu()
  }, [handleCloseMenu, route])

  useEffect(() => {
    loadUserData()
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.root}>
      <IconButton onClick={(e) => handleShowMenu(e)}>
        <AccountCircle />
      </IconButton>
      <Menu
        className={classes.menu}
        id="avatar-menu"
        anchorEl={state.menu}
        keepMounted
        open={Boolean(state.menu)}
        onClose={handleCloseMenu}
      >
        <div className={classes.profile}>
          {userData?.displayName && (
            <Typography variant="button" display="block" gutterBottom>
              {userData?.displayName}
            </Typography>
          )}

          <Typography variant="caption" display="block" gutterBottom>
            {userData?.email}
          </Typography>
        </div>

        <Divider />

        <div className={classes.menuItems}>
          <MenuItem
            className={classes.menuItem}
            onClick={() => handleSetRoute(RouteName.Settings)}
          >
            <SettingsIcon />
            {t`User.Settings`}
          </MenuItem>
        </div>

        <div className={classes.logoutButton}>
          <Button
            onClick={logout}
            variant="outlined"
            fullWidth
            size="small"
            color="secondary"
          >
            {t`Global.Sign_Out`}
          </Button>
        </div>
      </Menu>
    </div>
  )
}

export default Avatar
