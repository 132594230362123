import { makeStyles } from '@material-ui/styles'
import { NotFoundStyles } from './NotFound@styles'

const useStyles = makeStyles(NotFoundStyles)

const NotFound = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <h1 className={classes.header}>404</h1>
      <p className={classes.description}>
        No, tak to jste spatne, protoze tady nic neni.
      </p>
    </div>
  )
}

export default NotFound
