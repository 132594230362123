import { makeStyles } from '@material-ui/styles'
import { useContext } from 'react'
import { Alert } from '../../components/Alert'
import { AlertsContext } from '../../providers/alerts.provider'
import Routes from '../index'
import { rootStyles } from './Root@styles'

const useStyles = makeStyles(rootStyles)

const Root = () => {
  // hooks
  const classes = useStyles()

  // context
  const { alertsList } = useContext(AlertsContext)

  return (
    <div className={classes.root}>
      <div className={classes.alert}>
        {alertsList.map((alert: any, index: number) => (
          <Alert
            key={index}
            id={alert.id}
            title={alert.title}
            description={alert.description}
            type={alert.type}
          />
        ))}
      </div>
      <div>
        <Routes />
      </div>
    </div>
  )
}

export default Root
