import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Link, makeStyles,
  TextField
} from '@material-ui/core'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../../components/Table'
import { config } from '../../configs'
import { ContactsModel } from '../../models/contacts.model'
import { DataContext } from '../../providers/data.provider'
import { Collections, Documents } from '../../remote/Collections'
import {
  formatLongerTimestamp,
  getCurrentLongerTimestamp
} from '../../utils/datetime'
import { ContactsStyles } from './Contacts@styles'
import { ContactsStateTypes } from './Contacts@types'

const useStyles = makeStyles(ContactsStyles)

const Contacts = () => {
  // state
  const [state, setState] = useState<ContactsStateTypes>({
    loading: true,
    noteDialog: false,
    rowData: undefined,
    newNote: null,
    noteDialogEditable: false,
  })

  const { loading, noteDialog, rowData, noteDialogEditable, newNote } = state

  // hooks
  const classes = useStyles()
  const { t } = useTranslation()

  // init
  const collection = Collections.Management
  const documents = Documents.Contacts

  // context
  const { get, data, insert, update, remove } = useContext(DataContext)

  const handleNoteDialogActions = (rowData?: ContactsModel) => {
    if (noteDialog) {
      setState((prevState) => ({
        ...prevState,
        noteDialog: false,
        rowData: undefined,
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        noteDialog: true,
        rowData: rowData,
      }))
    }
  }

  const handleEditNoteDialog = ({ note }: any) => {
    if (noteDialogEditable) {
      setState((prevState) => ({
        ...prevState,
        noteDialogEditable: false,
        newNote: null,
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        noteDialogEditable: true,
        newNote: note,
      }))
    }
  }

  const handleChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      newNote: value,
    }))
  }

  const handleSaveNewNote = async () => {
    try {
      await update({
        collection,
        documents,
        id: rowData?.id || '',
        data: { note: newNote },
      })
      setState((prevState) => ({
        ...prevState,
        noteDialogEditable: false,
        noteDialog: false,
      }))
    } catch (err) {
      console.error(err)
    }
  }

  const columns = [
    {
      title: t`Contacts.Fullname`,
      field: 'fullname',
    },
    {
      title: t`Contacts.Phone`,
      field: 'phone',
      render: (rowData: any) => (
        <Link href={`tel:${rowData.phone}`}>{rowData.phone}</Link>
      ),
    },
    {
      title: t`Contacts.Email`,
      field: 'email',
      render: (rowData: any) => (
        <Link href={`mailto:${rowData.email}`}>{rowData.email}</Link>
      ),
    },
    {
      title: t`Contacts.Website`,
      field: 'website',
      render: (rowData: any) => (
        <Link href={rowData.website} target="_blank" rel="noreferrer">
          {rowData.website}
        </Link>
      ),
    },
    {
      title: t`Contacts.Activity`,
      field: 'activity',
      lookup: {
        call: t`Contacts.Call`,
        sendEmail: t`Contacts.Send_Email`,
        planMeeting: t`Contacts.Plan_Meeting`,
        nothing: t`Contacts.Nothing`,
      },
    },
    {
      title: t`Contacts.Note`,
      field: 'note',
      render: (rowData: any) => (
        <Button
          onClick={() => handleNoteDialogActions(rowData)}
          color="primary"
          size="small"
        >
          {t`Global.Show`}
        </Button>
      ),
    },
    {
      title: t`Contacts.Created_At`,
      field: 'createdAt',
      render: (rowData: any) => (
        <span>
          {formatLongerTimestamp(
            rowData.createdAt,
            config.datetime.format.long,
          )}
        </span>
      ),
    },
  ]

  const refresh = useCallback(() => {
    get({ collection, documents })
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }))
    }, 100)
  }, [collection, documents, get])

  useEffect(() => {
    refresh()
  }, [refresh])

  return (
    <div className={classes.root}>
      <Table
        title={t`Contacts.Header`}
        columns={columns}
        data={data}
        isLoading={loading}
        editable={{
          onRowAdd: (newData: any) =>
            new Promise(async (resolve, reject) => {
              const data = {
                ...newData,
                createdAt: getCurrentLongerTimestamp(),
              }
              await insert({ collection, documents, data })
              resolve(null)
            }),
          onRowDelete: async (oldData: any) =>
            new Promise(async (resolve, reject) => {
              await remove({ collection, documents, id: oldData.id })
              resolve(null)
            }),
          onRowUpdate: (newData: any) =>
            new Promise(async (resolve, reject) => {
              await update({
                collection,
                documents,
                id: newData.id,
                data: newData,
              })
              resolve(null)
            }),
        }}
      />

      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={() => handleNoteDialogActions()}
        open={noteDialog}
      >
        <DialogTitle id="note-dialog">{t`Contacts.Note`}</DialogTitle>
        <DialogContent dividers>
          {noteDialogEditable ? (
            <TextField
              id="editable-note"
              label={t`Global.Edit`}
              multiline
              fullWidth
              rowsMax={8}
              defaultValue={rowData?.note}
              onChange={(e) => handleChange(e.target.value)}
            />
          ) : (
            <span>{rowData ? rowData?.note : ''}</span>
          )}
        </DialogContent>
        <DialogActions>
          {noteDialogEditable ? (
            <>
              <Button
                variant="contained"
                onClick={handleSaveNewNote}
                color="primary"
              >
                {t`Global.Save`}
              </Button>
              <Button
                variant="text"
                onClick={() => handleNoteDialogActions()}
                color="secondary"
              >
                {t`Global.Cancel`}
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                onClick={() => handleEditNoteDialog({ note: rowData?.note })}
                color="primary"
              >
                {t`Global.Edit`}
              </Button>
              <Button
                variant="text"
                onClick={() => handleNoteDialogActions()}
                color="secondary"
              >
                {t`Global.Close`}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Contacts
