import { forwardRef, Ref } from 'react'
import { Button as MuiButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ButtonProps } from './Button@types'
import { ButtonStyles, createInlineStyles } from './Button@styles'

const useStyles = makeStyles(ButtonStyles)

function Button(props: ButtonProps, ref: Ref<HTMLButtonElement>) {
  const {
    variant,
    direction = 'row',
    backgroundOpacity = 1,
    paddingX,
    paddingY,
    marginX = 'auto',
    marginY = 0,
    marginTop,
    marginBottom,
    disabled = false,
    textAlign = 'center',
    noStyles = false,
    maxWidth = 'none',
    minWidth,
    borderSize = 0,
    justifyContent = 'center',
    textTransform,
    ...other
  } = props

  const styles =
    !noStyles &&
    createInlineStyles({
      variant,
      textTransform,
      backgroundOpacity,
      disabled,
      textAlign,
      maxWidth,
      minWidth,
      paddingX,
      paddingY,
      marginX,
      marginY,
      marginTop,
      marginBottom,
      borderSize,
    })

  // create styled classes
  const classes = useStyles({
    size: props.size || 'medium',
    direction,
    justifyContent,
  })

  if (variant === 'custom') {
    return (
      <button
        type="button"
        style={styles ? styles : undefined}
        className={props.className}
        onClick={props.onClick}
      >
        {props.children}
      </button>
    )
  }

  return (
    <MuiButton
      ref={ref}
      style={styles ? styles : undefined}
      variant={variant}
      disabled={disabled}
      disableElevation
      classes={{
        root: classes.root,
        label: classes.label,
      }}
      {...other}
    />
  )
}

export default forwardRef(Button)
