import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import './styles.css'

const Spinner = () => {
  const { t } = useTranslation()

  return (
    <div className='root'>
      <div className='spinner'>
        <div className='bounce1' />
        <div className='bounce2' />
        <div className='bounce3' />
      </div>
      <Typography variant="overline">
        {t`Global.Loading_Data`}
      </Typography>
    </div>
  )
}

export default Spinner