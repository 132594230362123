import { CircularProgress, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { LoadingStyles } from './Loading@styles'
import { LoadingProps } from './Loading@types'

const useStyles = makeStyles(LoadingStyles)

const Loading = (props: LoadingProps) => {
  // hooks
  const classes = useStyles()
  const { t } = useTranslation()

  // props
  const { description } = props

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.spin}>
          <CircularProgress />
          <p className={classes.description}>
            {description || t`Global.Loading`}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Loading
