import { createContext, ReactNode } from 'react';
import { Documents } from 'remote/Collections';
import firebase from 'remote/Firebase';

interface IUploadProviderProps {
  children: ReactNode
}

interface IContext {
  uploadFile: (files: File[], collection: string) => void
}

export const UploadContext = createContext<IContext>(null as any)

const UploadProvider = (props: IUploadProviderProps) => {
  const { children } = props

  const db = firebase.firestore()
  const storage = firebase.storage()
  const documents = Documents.Files

  const uploadFile = async (file: File[], collection: string) => {
    const storageRef = storage.ref()
    const fileRef = storageRef.child(file[0].name)
    await fileRef.put(file[0])
    db.collection(collection).doc(documents).update({
      images: firebase.firestore.FieldValue.arrayUnion({
        name: file[0].name,
        url: await fileRef.getDownloadURL()
      })
    })
  }

  return (
    <UploadContext.Provider
      value={{
        uploadFile
      }}
    >
      {children}
    </UploadContext.Provider>
  )
}

export default UploadProvider