import { Button, Grid, Paper, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { Form, Formik, useFormik } from "formik"
import { useSnackbar } from "notistack"
import { AuthContext } from "providers/auth.provider"
import { useCallback, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { createFormChangePasswordSchema } from "../Settings@config"
import { settingsPartsStyles } from "../Settings@styles"
import { ProfileSettingsStateTypes } from "../Settings@types"

const useStyles = makeStyles(settingsPartsStyles)

const ChangePassword = () => {
  // state
  const [state, setState] = useState<ProfileSettingsStateTypes>({
    passwordIsIncorrect: false,
    verificationEmailSend: false,
  })
  const { passwordIsIncorrect } = state

  // context
  const { setNewPassword } = useContext(AuthContext)

  // hooks
  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  // password form
  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: createFormChangePasswordSchema(),
    onSubmit: () => handleChangePassword(),
  })

  const handleChangePassword = useCallback(() => {
    if (
      formik.values.newPassword.length >= 8 &&
      formik.values.newPassword === formik.values.confirmNewPassword
    ) {
      try {
        setState((prevState) => ({
          ...prevState,
          passwordIsIncorrect: false,
        }))
        setNewPassword({ newPassword: formik.values.newPassword })
        formik.resetForm()
        enqueueSnackbar(
          t`Alert.Password_Was_Changed`,
          { variant: 'info' }
        )
      } catch (err) {
        console.error(err)
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        passwordIsIncorrect: true,
      }))
    }
    // eslint-disable-next-line
  }, [enqueueSnackbar, setNewPassword, t])

  return (
    <Paper className={classes.paper}>
      {passwordIsIncorrect && (
        <div className={classes.passwordAlert}>
          <Grid item xs={12}>
            <Alert severity="error">
              {t`Settings.Password_Is_Incorrect`}
            </Alert>
          </Grid>
        </div>
      )}

      <div className={classes.spacing}>
        <Formik
          initialValues={formik.initialValues}
          onSubmit={() => formik.handleSubmit()}
        >
          <Form>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  color="primary"
                  variant="outlined"
                  margin="normal"
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  placeholder={t`Settings.Minimal_Password_Lenght`}
                  label={t`Settings.New_Password`}
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  required
                  color="primary"
                  variant="outlined"
                  margin="normal"
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  type="password"
                  label={t`Settings.Confirm_New_Password`}
                  value={formik.values.confirmNewPassword}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} className={classes.changePasswordButton}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  size="large"
                >
                  {t`Global.Save_Changes`}
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Formik>
      </div>
    </Paper>
  )
}

export default ChangePassword