import { Grid, IconButton, Menu, MenuItem, MenuList, Tab, Typography, Zoom } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import { Spinner } from "components/Spinner";
import { RouteName } from 'enums/RouteName';
import useRoute from 'hooks/useRoute';
import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ITabList, ITabPanels } from 'types/tablist.types';
import { Category } from '../../components/Category';
import { DataContext } from '../../providers/data.provider';
import { FoodMenuContext } from '../../providers/foodMenu.provider';
import { Collections, Documents } from '../../remote/Collections';
import * as config from './FoodMenu@config';
import { foodMenuStyles } from './FoodMenu@styles';

interface IFoodMenuState {
  menu: null | HTMLElement
  value: string
}

const useStyles = makeStyles(foodMenuStyles)

const FoodMenu = () => {
  const [state, setState] = useState<IFoodMenuState>({
    menu: null,
    value: config.tabsValues.public
  })
  const { get, data } = useContext(DataContext)
  const { getCategories, isLoading } = useContext(FoodMenuContext)
  const { t } = useTranslation()
  const route = useRoute()
  const classes = useStyles()

  // remove settings
  const collection = Collections.Food_Menu
  const documents = Documents.Categories

  useEffect(() => {
    const refresh = () => {
      get({ collection, documents })
      getCategories()
    }

    refresh()
    // eslint-disable-next-line
  }, [collection, documents])

  const open = Boolean(state.menu);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setState((prevState) => ({
      ...prevState,
      menu: event.currentTarget
    }))
  };

  const handleClose = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      menu: null
    }))
  }, [])

  const handleChangeRoute = useCallback(() => {
    route.push(RouteName.FoodMenu_InsertCategory)
  }, [route])

  const handleChangeTab = (event: ChangeEvent<{}>, newValue: string) => {
    setState((prevState) => ({
      ...prevState,
      value: newValue
    }))
  }

  const tabsList: ITabList[] = [
    {
      label: t`Food_Menu.Public`,
      value: config.tabsValues.public
    },
    {
      label: t`Food_Menu.Private`,
      value: config.tabsValues.private
    },
  ]

  const tabPanels: ITabPanels[] = [
    {
      value: config.tabsValues.public,
      content: (
        <Grid container spacing={2}>
          {data.map((item: any) => {
            return item.state === 'public' && (
              <Grid key={item.id} item xs={12} md={3}>
                <Category {...item} />
              </Grid>
            )
          })}
        </Grid>
      )
    },
    {
      value: config.tabsValues.private,
      content: (
        <Grid container spacing={2}>
          {data.map((item: any) => {
            return item.state === 'private' && (
              <Grid key={item.id} item xs={12} md={3}>
                <Category {...item} />
              </Grid>
            )
          })}
        </Grid>
      )
    }
  ]

  const MenuComponent = () => (
    <Menu
      id="menu"
      keepMounted
      anchorEl={state.menu}
      open={open}
      onClose={handleClose}
      TransitionComponent={Zoom}
    >
      <MenuList>
        <MenuItem onClick={handleChangeRoute}>
          <Typography variant="inherit">
            {t`Food_Menu.Add_Category`}
          </Typography>
        </MenuItem>
      </MenuList>
    </Menu>
  )

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h4">{t`Food_Menu.Category`}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={1} justifyContent='flex-end'>
              <Grid item>
                <IconButton
                  onClick={handleClick}
                  aria-haspopup="true"
                  aria-controls='menu'
                >
                  <MoreVertIcon fontSize='large' />
                </IconButton>

                <MenuComponent />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {
          isLoading
            ? <Spinner />
            : (
              <TabContext value={state.value}>
                <TabList
                  onChange={handleChangeTab}
                  textColor="primary"
                  indicatorColor="primary"
                >
                  {tabsList.map((tab, index) => (
                    <Tab key={index} label={tab.label} value={tab.value} />
                  ))}
                </TabList>

                {tabPanels.map((tabPanel, index) => (
                  <TabPanel
                    color="primary"
                    key={index}
                    value={tabPanel.value}
                    className={classes.tabPanel}
                  >
                    {tabPanel.content}
                  </TabPanel>
                ))}
              </TabContext>
            )
        }
      </Grid>
    </Grid>
  )
}

export default FoodMenu
