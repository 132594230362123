import { createStyles, Theme } from '@material-ui/core'

export const LoadingStyles = (theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100vh',
      zIndex: 99999,
      background: theme.palette.background.default,
      display: 'flex',
      alignItems: 'center',
    },
    content: {
      width: '100%',
    },
    spin: {
      padding: theme.spacing(2),
      boxShadow: theme.shadows[10],
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.background.paper,
      textAlign: 'center',
      width: 100,
      margin: 'auto',
      '& svg': {
        color: theme.palette.primary.light,
      },
    },
    description: {
      marginTop: theme.spacing(2),
      // fontWeight: theme.typography.fontWeightBold,
    },
  })
