import { createContext, ReactNode, useState } from 'react';

import { Collections, SubCollections } from '../remote/Collections';
import firebase from '../remote/Firebase';
import { IDailyMenus, IDailyMenuSoups } from '../types/daily-menu.types';

type MenusProviderPropsTypes = {
  children: ReactNode;
};

interface IMenusProviderState {
  menus: IDailyMenus[] | [];
  soups: IDailyMenuSoups[] | [];
  loading: boolean
}

type Context = {
  insertSoup: (documents: string, data: any) => void;
  insertMenu: (documents: string, data: any) => void;
  updateSoup: (id: string, documents: string, data: any) => void;
  updateMenu: (id: string, documents: string, data: any) => void;
  getMenus: (documents: string) => void;
  getSoups: (documents: string) => void;
  removeMenu: (id: string, documents: string) => void;
  removeSoup: (id: string, documents: string) => void;
  menus: IDailyMenus[] | [];
  soups: IDailyMenuSoups[] | [];
  loading: boolean;
};

export const MenusContext = createContext<Context>(null as any);

const MenusProvider = (props: MenusProviderPropsTypes) => {
  // state
  const [state, setState] = useState<IMenusProviderState>({
    menus: [],
    soups: [],
    loading: true
  });
  const { menus, soups, loading } = state;

  // props
  const { children } = props;

  // settings
  const db = firebase.firestore();
  const collection = Collections.Daily_Menu;

  /**
   * cleaning all states
   */
  const cleaningState = async () => {
    setState({
      menus: [],
      soups: [],
      loading: true
    });
  };

  const insertSoup = async (documents: string, data: any) => {
    await db
      .collection(collection)
      .doc(documents)
      .collection(SubCollections.Soups)
      .add(data);
  };

  const insertMenu = async (documents: string, data: any) => {
    await db
      .collection(collection)
      .doc(documents)
      .collection(SubCollections.Menus)
      .add(data);
  };

  const updateSoup = async (id: string, documents: string, data: any) => {
    await db
      .collection(collection)
      .doc(documents)
      .collection(SubCollections.Soups)
      .doc(id)
      .update(data);
  };

  const updateMenu = async (id: string, documents: string, data: any) => {
    await db
      .collection(collection)
      .doc(documents)
      .collection(SubCollections.Menus)
      .doc(id)
      .update(data);
  };

  const getMenus = async (documents: string) => {
    await cleaningState();
    await db
      .collection(collection)
      .doc(documents)
      .collection(SubCollections.Menus)
      .onSnapshot((s) => {
        const items: any = s.docs.map((doc) => ({ // TODO: typing
          id: doc.id,
          ...doc.data()
        }));
        setState((prevState) => ({
          ...prevState,
          menus: items,
          loading: false
        }));
        return items;
      });
  };

  const getSoups = async (documents: string) => {
    await cleaningState();
    await db
      .collection(collection)
      .doc(documents)
      .collection(SubCollections.Soups)
      .onSnapshot((s) => {
        const items: any = s.docs.map((doc) => ({ // TODO: typing
          id: doc.id,
          ...doc.data()
        }));
        setState((prevState) => ({
          ...prevState,
          soups: items,
          loading: false
        }));
        return items;
      });
  };

  const removeMenu = async (id: string, documents: string) => {
    await db
      .collection(collection)
      .doc(documents)
      .collection(SubCollections.Menus)
      .doc(id)
      .delete();
  };

  const removeSoup = async (id: string, documents: string) => {
    await db
      .collection(collection)
      .doc(documents)
      .collection(SubCollections.Soups)
      .doc(id)
      .delete();
  };

  return (
    <MenusContext.Provider
      value={{
        insertSoup,
        insertMenu,
        updateSoup,
        updateMenu,
        getMenus,
        getSoups,
        removeMenu,
        removeSoup,
        menus,
        soups,
        loading
      }}
    >
      {children}
    </MenusContext.Provider>
  );
};

export default MenusProvider;