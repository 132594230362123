import { createStyles, Theme } from '@material-ui/core'

export const fileboxStyles = (theme: Theme) =>
  createStyles({
    root: {},
    image: {
      width: '100%',
      height: 'auto',
      margin: 0,
      padding: 0,
    },
  })
