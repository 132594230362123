import { Theme, createStyles } from "@material-ui/core";

export const CheckerStyles = (theme: Theme) =>
  createStyles({
    greenIcon: {
      color: 'green',
      width: 18,
      height: 18
    },
    redIcon: {
      color: 'red',
      width: 18,
      height: 18
    },
  })