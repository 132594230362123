import { initYup } from 'plugins/yup'
import i18n from 'services/i18n'
import t from 'i18next'

export const formFoodSchema = () => {
  const yup = initYup(i18n.language)

  return yup
    .object({
      name: yup
        .string()
        .required()
        .label(t.t`Food_Menu.Name`),
      description: yup.string().label(t.t`Food_Menu.Description`),
      price: yup
        .number()
        .required()
        .label(t.t`Food_Menu.Price`),
      amount: yup
        .string()
        .label(t.t`Food_Menu.Amount`),
    })
    .required()
}

export const formCategorySchema = () => {
  const yup = initYup(i18n.language)

  return yup
    .object({
      csName: yup
        .string()
        .required()
        .label(t.t`Food_Menu.Cs_Name`),
      enName: yup
        .string()
        .label(t.t`Food_Menu.En_Name`),
    })
    .required()
}

export const amountTypes = [
  {
    value: 'l',
    label: 'l',
  },
  {
    value: 'ml',
    label: 'ml',
  },
  {
    value: 'g',
    label: 'g',
  },
  {
    value: 'kg',
    label: 'kg',
  },
  {
    value: 'porce',
    label: 'porce',
  },
  {
    value: 'ks',
    label: 'ks',
  },
]

export const privateTypes = [
  {
    value: 'public',
    label: 'Food_Menu.Public',
  },
  {
    value: 'private',
    label: 'Food_Menu.Private',
  },
]
