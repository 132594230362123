import { makeStyles } from '@material-ui/styles'
import { IconButton } from '@material-ui/core'
import { IconError, IconInfo, IconSuccess, IconWarning } from '../Icons'
import CloseIcon from '@material-ui/icons/Close'
import { AlertTypes } from '../../types/alerts.types'
import { alertStyles } from './Alert@styles'
import { AlertPropsTypes } from './Alert@types'
import { useContext } from 'react'
import { AlertsContext } from '../../providers/alerts.provider'
import { useEffect } from 'react'

const useStyles = makeStyles(alertStyles)

const Alert = (props: AlertPropsTypes) => {
  // props
  const { id, title, description, type = AlertTypes.info } = props

  // hooks
  const classes = useStyles()
  const colorStyles = `${classes[type]}`

  // context
  const { removeAlert, alertTimeout } = useContext(AlertsContext)

  // close alert manually
  const handleRemoveAlert = () => {
    removeAlert(id)
  }

  // close notification only if is not error type
  useEffect(() => {
    if (type !== AlertTypes.error) {
      setTimeout(() => {
        removeAlert(id)
      }, alertTimeout)
    }
  }, [alertTimeout, id, removeAlert, type])

  return (
    <div className={[classes.root, colorStyles].join(' ')}>
      <div className={classes.icon}>
        {type === AlertTypes.info ? (
          <IconInfo size="22" />
        ) : type === AlertTypes.success ? (
          <IconSuccess size="22" />
        ) : type === AlertTypes.warning ? (
          <IconWarning size="22" />
        ) : (
          <IconError size="22" />
        )}
      </div>
      <div className={classes.content}>
        {title && <div className={classes.title}>{title}</div>}
        <div className={classes.description}>{description}</div>
        {type === AlertTypes.error && (
          <div className={classes.close}>
            <IconButton onClick={handleRemoveAlert} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  )
}

export default Alert
