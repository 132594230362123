import { createContext, ReactNode, useEffect, useState } from 'react'
import firebase from '../remote/Firebase'

interface ICredentials {
  email: string
  password: string
}

interface IResetPassword {
  email: string
}

interface INewPassword {
  newPassword: string
}

interface INewEmail {
  newEmail: string
}

type Context = {
  loading: boolean
  signed: boolean
  login: ({ email, password }: ICredentials) => void
  logout: () => void
  userData: any
  userId: string | null
  sendPasswordResetEmail: ({ email }: IResetPassword) => void
  setNewEmail: ({ newEmail }: INewEmail) => void
  setNewPassword: ({ newPassword }: INewPassword) => void
}

type AuthProviderProps = {
  children: ReactNode
}

export const AuthContext = createContext<Context>(null as any)

const AuthProvider = (props: AuthProviderProps) => {
  // state
  const [loading, setLoading] = useState<boolean>(true)
  // const [signed, setSigned] = useState<boolean>(false)
  const [userData, setUserData] = useState<any>(null)
  const [userId, setUserId] = useState<string | null>(null)

  //props
  const { children } = props

  const loginGuard = async () => {
    try {
      await firebase.auth().onAuthStateChanged((data) => setUserData(data))
      await firebase
        .auth()
        .onAuthStateChanged((user) => setUserId(user ? user.uid : null))
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loginGuard()
  }, [])

  const login = async ({ email, password }: ICredentials) => {
    try {
      setLoading(true)
      console.log(email);
      console.log(password);
      await firebase.auth().signInWithEmailAndPassword(email, password)
      await firebase.auth().onAuthStateChanged((data) => setUserData(data))
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }

  const logout = async () => {
    await firebase.auth().signOut()
  }

  /* USER PROFILE MANAGING */
  const sendPasswordResetEmail = async ({ email }: IResetPassword) => {
    await firebase.auth().sendPasswordResetEmail(email).then(() => {
      console.log('Password link was send')
    }).catch((error) => {
      console.error(error);
    })
  }

  const setNewEmail = async ({ newEmail }: INewEmail) => {
    await firebase.auth().currentUser?.updateEmail(newEmail).then(() => {
      console.log('Email was changed')
    }).catch((error) => {
      console.error(error);
    })
  }

  const setNewPassword = async ({ newPassword }: INewPassword) => {
    await firebase.auth().currentUser?.updatePassword(newPassword).then(() => {
      console.log('Password was changed')
    }).catch((error) => {
      console.error(error);
    })
  }

  return (
    <AuthContext.Provider
      value={{
        loading,
        signed: false,
        login,
        logout,
        userData,
        userId,
        sendPasswordResetEmail,
        setNewEmail,
        setNewPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
