import { initYup } from '../../plugins/yup'
import i18n from '../../services/i18n'
import t from 'i18next'

export const createFormLoginSchema = () => {
  const yup = initYup(i18n.language)

  return yup.object({
    email: yup
      .string()
      .required()
      .label(t.t`Login.Email_Address`),
    password: yup
      .string()
      .required()
      .label(t.t`Login.Password`),
  })
}

export const formLoginSchema = () => {
  const yup = initYup(i18n.language)

  return yup.object({
    email: yup
      .string()
      .required()
      .label(t.t`Login.Email_Address`),
    password: yup
      .string()
      .required()
      .label(t.t`Login.Password`),
  })
}

export const createFormForgotPasswordSchema = () => {
  const yup = initYup(i18n.language)

  return yup.object({
    email: yup
      .string()
      .required()
      .label(t.t`Login.Email_Address`),
  })
}
