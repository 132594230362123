import { Button, Card, CardActions, CardContent, CardHeader, CardMedia, Chip, Grid, makeStyles, Typography } from '@material-ui/core';
import { RouteName } from 'enums/RouteName';
import useRoute from 'hooks/useRoute';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { foodStyles } from "./Food@styles";
import { IFoodProps } from './Food@types';

const useStyles = makeStyles(foodStyles)

const Food = (props: IFoodProps) => {
  const { id: categoryId } = useParams<{ id: string }>();
  const { t } = useTranslation()
  const route = useRoute()
  const classes = useStyles()

  const isPublic = props.state === 'public'

  const handleEditFood = useCallback(() => {
    const foodId = props.id

    route.push(`${RouteName.FoodMenu_EditFood}/${categoryId}/${foodId}`)
  }, [props, categoryId, route])

  const SubheaderContent = () => {
    if (props.amount.length < 1) {
      return (
        <span>
          {`${props.price} ${t`Common.Currency.CZK`}`}
        </span>
      )
    } else {
      return (
        <span>
          {`${props.price} ${t`Common.Currency.CZK`} | ${props.amount}${props.amountType}`}
        </span>
      )
    }
  }

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader
          title={props.name}
          subheader={(
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SubheaderContent />
              </Grid>

              <Grid item xs={12}>
                <Chip
                  label={isPublic ? t`Global.Public` : t`Global.Private`}
                  variant="outlined"
                  size="small"
                  color={isPublic ? 'primary' : 'secondary'}
                />
              </Grid>
            </Grid>
          )}
        />
        {
          props.images && props.images?.length >= 1 && (
            <CardMedia
              className={classes.media}
              image={props.images[0].url}
            />
          )
        }
        <CardContent>
          <Typography variant="body2">
            {props.description}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={handleEditFood}
          >
            <Typography variant="inherit">
              {t`Global.Edit`}
            </Typography>
          </Button>
        </CardActions>
      </Card>
    </Grid>
  )
}

export default Food