import { SnackbarProvider } from 'notistack'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Loading } from './components/Loading'
import AppProvider from './providers/app.provider'
import MasterProvider from './providers/master.provider'
import { Root } from './routes/Root'
import './services/i18n'

const Document = () => {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <AppProvider>
          <MasterProvider>
            <SnackbarProvider
              maxSnack={4}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <Root />
            </SnackbarProvider>
          </MasterProvider>
        </AppProvider>
      </Suspense>
    </Router>
  )
}

ReactDOM.render(<Document />, document.getElementById('root'))
