export enum CompareResult {
  Lower = -1,
  Equals = 0,
  Higher = 1,
}

export enum TimestampUnit {
  Second = 1,
  Minute = 60,
  Hour = 3600,
  Day = 86400,
}

export enum HttpErrorCode {
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
}
