import { Avatar, Grid, Typography } from '@material-ui/core'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import CardTravelIcon from '@material-ui/icons/CardTravel'
import { makeStyles } from '@material-ui/styles'
import { NotificationTypes } from '../../models/notifications.model'
import { formatTimestampFromNow } from '../../utils/datetime'
import { notificationStyles } from './Notifications@styles'
import {
  NotificationIconPropsTypes,
  NotificationPropsTypes,
} from './Notifications@types'

const useStyles = makeStyles(notificationStyles)

const NotificationIcon = (props: NotificationIconPropsTypes) => {
  const { type } = props

  if (type === NotificationTypes.Order) {
    return <CardTravelIcon />
  }

  if (type === NotificationTypes.Question) {
    return <QuestionAnswerIcon />
  }

  return <AssignmentTurnedInIcon />
}

export const Notification = (props: NotificationPropsTypes) => {
  // props
  const { title, date, type } = props

  // hooks
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={2} className={classes.icon}>
          <div>
            <Avatar className={classes.avatar}>
              <NotificationIcon type={type} />
            </Avatar>
          </div>
        </Grid>

        <Grid item xs={10} className={classes.content}>
          <div>
            <Typography>{title}</Typography>
            <Typography
              color="textSecondary"
              variant="caption"
              display="block"
              gutterBottom
            >
              {formatTimestampFromNow(date)}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
