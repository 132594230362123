import { Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Opening from 'components/Opening/Opening'
import { IOpeningCard, IOpeningHoursDayProps } from './OpeningHours@types'
import { openingCards } from './OpeningHours@config'

const OpeningHoursDay = ({ day }: IOpeningHoursDayProps) => {
  const { t } = useTranslation()

  return (
    <Grid container spacing={4}>
      {openingCards.map((card: IOpeningCard) => (
        <Opening key={card.id} place={card.place} day={day} title={t`${card.title}`} />
      ))}
    </Grid>
  )
}

export default OpeningHoursDay
