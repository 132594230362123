import { Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import DailyMenuDay from './DailyMenuDay'
import { dailyMenuStyles } from './DailyMenu@styles'
import * as config from './DailyMenu@config'

const useStyles = makeStyles(dailyMenuStyles)

const DailyMenu = () => {
  // state
  const [value, setValue] = useState<string>(config.tabsValues.monday)

  // hooks
  const classes = useStyles()
  const { t } = useTranslation()

  const handleChangeTab = (event: ChangeEvent<{}>, newValue: string) => {
    setValue(newValue)
  }

  const tabsList = [
    {
      label: t`Common.Days.Monday`,
      value: config.tabsValues.monday,
    },
    {
      label: t`Common.Days.Tuesday`,
      value: config.tabsValues.tuesday,
    },
    {
      label: t`Common.Days.Wednesday`,
      value: config.tabsValues.wednesday,
    },
    {
      label: t`Common.Days.Thursday`,
      value: config.tabsValues.thursday,
    },
    {
      label: t`Common.Days.Friday`,
      value: config.tabsValues.friday,
    },
    {
      label: t`Common.Days.Saturday`,
      value: config.tabsValues.saturtday,
    },
    {
      label: t`Common.Days.Sunday`,
      value: config.tabsValues.sunday,
    },
  ]

  const tabPanels = [
    {
      value: config.tabsValues.monday,
      content: <DailyMenuDay documents={config.DailyMenuDocuments.monday} />,
    },
    {
      value: config.tabsValues.tuesday,
      content: <DailyMenuDay documents={config.DailyMenuDocuments.tuesday} />,
    },
    {
      value: config.tabsValues.wednesday,
      content: <DailyMenuDay documents={config.DailyMenuDocuments.wednesday} />,
    },
    {
      value: config.tabsValues.thursday,
      content: <DailyMenuDay documents={config.DailyMenuDocuments.thursday} />,
    },
    {
      value: config.tabsValues.friday,
      content: <DailyMenuDay documents={config.DailyMenuDocuments.friday} />,
    },
    {
      value: config.tabsValues.saturtday,
      content: <DailyMenuDay documents={config.DailyMenuDocuments.saturday} />,
    },
    {
      value: config.tabsValues.sunday,
      content: <DailyMenuDay documents={config.DailyMenuDocuments.sunday} />,
    },
  ]

  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <TabList
          onChange={handleChangeTab}
          textColor="primary"
          indicatorColor="primary"
        >
          {tabsList.map((tab, index) => (
            <Tab key={index} label={tab.label} value={tab.value} />
          ))}
        </TabList>

        {tabPanels.map((tabPanel, index) => (
          <TabPanel
            color="primary"
            key={index}
            value={tabPanel.value}
            className={classes.tabPanel}
          >
            {tabPanel.content}
          </TabPanel>
        ))}
      </TabContext>
    </div>
  )
}

export default DailyMenu
