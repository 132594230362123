export enum NotificationTypes {
  Question = 'questions',
  Order = 'order',
  Task = 'task',
}

export type NotificationsModel = {
  id?: string
  type: NotificationTypes
  title: string
  created_at: number
  hasRead: boolean
}
