import { Theme, createStyles } from '@material-ui/core'
import { Colors } from '../../theme/Palette'

export const settingsStyles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    tabPanel: {
      padding: theme.spacing(3, 0),
    },
  })

export const settingsPartsStyles = (theme: Theme) =>
  createStyles({
    generalSettingsRoot: {},
    profileSettingsRoot: {},
    notificationsSettingsRoot: {},
    paper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(4),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    spacing: {
      marginTop: theme.spacing(2),
    },
    middle: {
      display: 'flex',
      alignItems: 'center',
    },
    right: {
      alignItems: 'right',
    },
    fullWidth: {
      width: '100%',
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
    },
    // General settings
    avatarContent: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(8),
    },
    avatar: {
      width: theme.spacing(16),
      height: theme.spacing(16),
      marginBottom: theme.spacing(2),
      fontSize: theme.typography.pxToRem(48),
      backgroundColor: Colors.green.dark,
    },
    textConditions: {
      maxWidth: 200,
      margin: 'auto',
      textAlign: 'center',
    },
    uploadButton: {
      marginTop: theme.spacing(4),
      width: '100%',
      textAlign: 'center',
    },
    // Profile
    verificationAlert: {
      marginTop: theme.spacing(2),
    },
    personalForm: {
      marginTop: theme.spacing(4),
    },
    personalFormSendButton: {
      textAlign: 'right',
    },
    passwordAlert: {
      marginTop: theme.spacing(2)
    },
    changePasswordButton: {
      textAlign: 'right'
    }
  })
