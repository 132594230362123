import { Theme, createStyles } from "@material-ui/core";

export const foodFormStyles = (theme: Theme) =>
  createStyles({
    root: {},
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      height: 'auto',
      border: '1px solid',
      borderColor: 'transparent',
    },
  })

export const categoryFormStyles = (theme: Theme) =>
  createStyles({
    root: {},
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      height: 'auto',
      border: '1px solid',
      borderColor: 'transparent',
    },
  })