import { createStyles, Theme } from '@material-ui/core'

export const LoginStyles = (theme: Theme) =>
  createStyles({
    root: {
      height: '100vh'
    },
    image: {
      backgroundImage: 'url(https://source.unsplash.com/random)',
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(3),
      backgroundColor: theme.palette.secondary.main,
    },
    header: {
      marginBottom: theme.spacing(6)
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(3, 0, 2)
    },
    link: {
      cursor: 'pointer'
    }
  })
