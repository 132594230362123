import { makeStyles } from "@material-ui/styles"
import { Checker } from "../Checker"
import { LabelStyles } from "./Label@styles"
import { LabelPropsTypes } from "./Label@types"

const useStyles = makeStyles(LabelStyles)

const Label = (props: LabelPropsTypes) => {
  // props
  const { label, value, checker } = props

  // hooks
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.label}>{label}:</div>
      {
        value
          ? <div className={classes.value}>{value}</div>
          : <div className={classes.value}><Checker checker={checker || false} /></div>
      }
    </div>
  )
}

export default Label