import { Grid, makeStyles, Typography, Button } from "@material-ui/core"
import { Paper } from "components/Paper"
import { useSnackbar } from "notistack"
import { useCallback } from "react"
import { useTranslation } from "react-i18next"
import { fileboxStyles } from "./FileBox@styles"

interface IFileBoxProps {
  fileType: 'image' | 'doc'
  url: string
  name: string
  onRemove?: () => void
}

const useStyles = makeStyles(fileboxStyles)

const FileBox = (props: IFileBoxProps) => {
  const { fileType, url, name, onRemove } = props

  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const onBlindRemove = useCallback(() => {
    enqueueSnackbar(
      t`Alert.Ouch_Something_Was_Wrong_Please_Contact_Support`,
      { variant: 'error' }
    )
  }, [t, enqueueSnackbar])

  if (fileType === 'image') {
    return (
      <Paper>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <img
              className={classes.image}
              src={url}
              alt={name}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant='text'
              size="small"
              fullWidth
              onClick={onRemove || onBlindRemove}
            >
              <Typography variant='inherit' color='error'>
                {t`Global.Remove`}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Paper>
    )
  }

  return (
    <div>Filebox</div>
  )
}

export default FileBox