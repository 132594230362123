import { makeStyles } from '@material-ui/core'
import { Close as CloseIcon, Done as DoneIcon } from '@material-ui/icons'
import { TableCheckIconStyles } from './Table@styles'
import { TableCheckIconPropsTypes } from './Table@types'

/* TableCheckIcon component */
const useTableCheckIconStyles = makeStyles(TableCheckIconStyles)

export const TableCheckIcon = (props: TableCheckIconPropsTypes) => {
  const { rowItem } = props

  const classes = useTableCheckIconStyles()

  if (rowItem) {
    return <DoneIcon className={classes.greenIcon} />
  }

  return <CloseIcon className={classes.redIcon} />
}
