import { Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { ChangeEvent, useCallback, useState } from 'react'

import { useTranslation } from 'react-i18next'
import * as config from './OpeningHours@config'
import { openingHoursStyles } from './OpeningHours@styles'
import OpeningHoursDay from './OpeningHoursDay'

const useStyles = makeStyles(openingHoursStyles)

const OpeningHours = () => {
  const [value, setValue] = useState<string>(config.tabsValues.monday)

  const classes = useStyles()
  const { t } = useTranslation()

  const handleChangeTab = useCallback((_: ChangeEvent<{}>, newValue: string) => {
    setValue(newValue)
  }, [])

  const tabsList = [
    {
      label: t`Common.Days.Monday`,
      value: config.tabsValues.monday,
    },
    {
      label: t`Common.Days.Tuesday`,
      value: config.tabsValues.tuesday,
    },
    {
      label: t`Common.Days.Wednesday`,
      value: config.tabsValues.wednesday,
    },
    {
      label: t`Common.Days.Thursday`,
      value: config.tabsValues.thursday,
    },
    {
      label: t`Common.Days.Friday`,
      value: config.tabsValues.friday,
    },
    {
      label: t`Common.Days.Saturday`,
      value: config.tabsValues.saturtday,
    },
    {
      label: t`Common.Days.Sunday`,
      value: config.tabsValues.sunday,
    },
  ]

  const tabPanels = [
    {
      value: config.tabsValues.monday,
      content: <OpeningHoursDay day={config.OpeningHoursDocuments.monday} />,
    },
    {
      value: config.tabsValues.tuesday,
      content: <OpeningHoursDay day={config.OpeningHoursDocuments.tuesday} />,
    },
    {
      value: config.tabsValues.wednesday,
      content: <OpeningHoursDay day={config.OpeningHoursDocuments.wednesday} />,
    },
    {
      value: config.tabsValues.thursday,
      content: <OpeningHoursDay day={config.OpeningHoursDocuments.thursday} />,
    },
    {
      value: config.tabsValues.friday,
      content: <OpeningHoursDay day={config.OpeningHoursDocuments.friday} />,
    },
    {
      value: config.tabsValues.saturtday,
      content: <OpeningHoursDay day={config.OpeningHoursDocuments.saturday} />,
    },
    {
      value: config.tabsValues.sunday,
      content: <OpeningHoursDay day={config.OpeningHoursDocuments.sunday} />,
    },
  ]

  return (
    <div className={classes.root}>
      <TabContext value={value}>
        <TabList onChange={handleChangeTab} textColor="primary" indicatorColor="primary">
          {tabsList.map((tab, index) => (
            <Tab key={index} label={tab.label} value={tab.value} />
          ))}
        </TabList>

        {tabPanels.map((tabPanel, index) => (
          <TabPanel color="primary" key={index} value={tabPanel.value} className={classes.tabPanel}>
            {tabPanel.content}
          </TabPanel>
        ))}
      </TabContext>
    </div>
  )
}

export default OpeningHours
