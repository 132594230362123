import { Theme, createStyles } from '@material-ui/core'

export const foodStyles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    card: {
      maxWidth: 345,
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      height: 'auto',
      border: '1px solid',
      borderColor: 'transparent',
    },
    media: {
      height: 0,
      paddingTop: '56.25%',
    },
  })
