import { createStyles, Theme } from '@material-ui/core'

export const paperStyles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      height: 'auto',
      border: '1px solid',
      borderColor: 'transparent',
    },
  })
