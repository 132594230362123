import createMuiTheme, {
  ThemeOptions,
} from '@material-ui/core/styles/createTheme'
import { ThemeOverrides } from './Overrides'
import { Colors } from './Palette'

const paletteLight: ThemeOptions['palette'] = {
  type: 'light',
  primary: {
    main: Colors.primary.main,
  },
  secondary: {
    main: Colors.red.main,
  },
  success: {
    main: Colors.green.main,
  },
  warning: {
    main: Colors.orange.main,
  },
  error: {
    main: Colors.red.main,
  },
  background: {
    default: Colors.white.dark,
  },
}

const paletteDark: ThemeOptions['palette'] = {
  type: 'dark',
  primary: {
    main: Colors.primary.main,
  },
  secondary: {
    main: Colors.secondary.main,
  },
  background: {
    default: Colors.black.dark,
    paper: Colors.black.light,
  },
}

export const themeLight = createMuiTheme({
  palette: paletteLight,
  overrides: ThemeOverrides({ palette: paletteLight }),
})

export const themeDark = createMuiTheme({
  palette: paletteDark,
  overrides: ThemeOverrides({ palette: paletteDark }),
})
