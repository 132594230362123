import { CompareResult } from '../../@typings'
import { config } from '../../configs'
import dayjs from 'dayjs'
import cs from 'dayjs/locale/cs'
import relativeTimePlugin from 'dayjs/plugin/relativeTime'
import dateFormats from './date-formats'

// init date time
dayjs.extend(relativeTimePlugin)
dayjs.locale({
  ...cs,
  weekStart: 1,
})

export { dayjs as datetime }

/**
 * GET CURRENT LONGER TIMESTAMP
 * ---
 * Retrieves current timestamp in seconds as default
 */
 export function getCurrentLongerTimestamp() {
  return Date.now()
}

/**
 * GET CURRENT TIMESTAMP
 * ---
 * Retrieves current timestamp in seconds as default
 * @param divider
 */
export function getCurrentTimestamp(divider = 1000) {
  return Date.now() / divider
}

/**
 * FORMAT LONGER TIMESTAMP
 * ---
 * Show how many hours or mins or secs the date is ago from now
 * @param timestamp
 */
 export function formatLongerTimestamp(
  timestamp: number,
  format: string = config.datetime.format.initial,
): string {
  const date = dayjs.unix(timestamp/ 1000)
  return date.format(format)
}

/**
 * FORMAT TIMESTAMP
 * ---
 * Show how many hours or mins or secs the date is ago from now
 * @param timestamp
 */
export function formatTimestamp(
  timestamp: number,
  format: string = config.datetime.format.initial,
): string {
  const date = dayjs.unix(timestamp)
  return date.format(format)
}

/**
 * FORMAT TIMESTAMP RANGE
 * @param since
 * @param till
 */
export function formatTimestampRange(since: number, till: number): string {
  const dateBegin = dayjs.unix(since)
  const dateEnd = dayjs.unix(till)

  return `${dateBegin.format(
    config.datetime.format.rangeBegin,
  )} - ${dateEnd.format(config.datetime.format.rangeEnd)}`
}

/**
 * DATE FROM NOW
 * ---
 * Show how many hours or mins or secs the date is ago from now
 * @param timestamp
 */
export function formatTimestampFromNow(
  timestamp: number,
  format: string | null = null,
  // locale: string = 'cs',
): string {
  const date = dayjs.unix(timestamp)

  // create now
  const now = dayjs()

  // less than 7 days before
  if (now.diff(date, 'day') <= 7) {
    return date.from(now)
  }

  // use given format if exists
  if (format !== null) {
    return date.format(format)
  }

  const yearBegin = now.startOf('year')

  // use initial date format for dates before current year
  if (date.isBefore(yearBegin)) {
    return date.format(config.datetime.format.initial)
  }

  // use short date format for within year
  return date.format(config.datetime.format.short)
}

/**
 * CHECK TIMESTAMP AGE
 * @param timestamp
 * @param checkAge
 */
export function checkTimestampAge(
  timestamp: number,
  checkAge: number,
): CompareResult {
  const age = Date.now() / 1000 - timestamp

  if (age < checkAge) return CompareResult.Lower
  if (age > checkAge) return CompareResult.Higher

  return CompareResult.Equals
}

/**
 * PARSE TIMESTAMP DIFF
 * @param t1
 * @param now
 */
export function parseTimestampDiff(t1: number, t2: number = Date.now() / 1000) {
  const total = t1 >= t2 ? t1 - t2 : t2 - t1

  return {
    total,
    days: Math.floor(total / (60 * 60 * 24)),
    hours: Math.floor((total / (60 * 60)) % 24),
    minutes: Math.floor((total / 60) % 60),
    seconds: Math.floor(total % 60),
  }
}

// return date in format 'DD. MM. YYYY' 
export const formatIsoDate = (date: string) => {
  return dayjs(date).format(dateFormats.formatDateLocal)
}

// return today date in format 'YYYY-MM-DD' time in 'DD:MM'
export const getTodayInSystemFormat = () => {
  const date = formatTimestamp(getCurrentTimestamp(), dateFormats.formatDateIso)

  const time = formatTimestamp(
    getCurrentTimestamp(),
    dateFormats.formatTimeLocal,
  )
  return {
    date,
    time,
  }
}
