import { Grid, Paper, Typography, TextField } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { partsStyles } from './DailyMenu@styles'

const useStyles = makeStyles(partsStyles)

const DummySkeleton = () => {
  // hooks
  const classes = useStyles()

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={6}>
                <Skeleton width="fullwidth">
                  <Typography variant="overline" gutterBottom>
                    Dummy header
                  </Typography>
                </Skeleton>
              </Grid>
              <Grid item xs={6}>
                <Skeleton width="fullwidth">
                  <Typography variant="overline" gutterBottom>
                    Dummy header
                  </Typography>
                </Skeleton>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={2}>
                <Skeleton width="fullwidth">
                  <TextField
                    id="dummy-input"
                    name="menuAmount"
                    variant="outlined"
                    fullWidth
                  />
                </Skeleton>
              </Grid>
              <Grid item xs={8}>
                <Skeleton width="fullwidth">
                  <TextField
                    id="dummy-input"
                    name="menuAmount"
                    variant="outlined"
                    fullWidth
                  />
                </Skeleton>
              </Grid>
              <Grid item xs={2}>
                <Skeleton width="fullwidth">
                  <TextField
                    id="dummy-input"
                    name="menuAmount"
                    variant="outlined"
                    fullWidth
                  />
                </Skeleton>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={10}>
                <Skeleton width="fullwidth">
                  <TextField
                    id="dummy-input"
                    name="menuAmount"
                    variant="outlined"
                    fullWidth
                  />
                </Skeleton>
              </Grid>
              <Grid item xs={2}>
                <Skeleton width="fullwidth">
                  <TextField
                    id="dummy-input"
                    name="menuAmount"
                    variant="outlined"
                    fullWidth
                  />
                </Skeleton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default DummySkeleton
