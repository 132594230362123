import { createStyles, Theme } from '@material-ui/core'
import { Coloring } from '../../styles/Styles@types'

export const NotFoundStyles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(10),
      width: '100%',
      textAlign: 'center',
    },
    header: {
      fontSize: theme.typography.pxToRem(100),
      color: Coloring.GreyLight,
      margin: 0,
    },
    description: {
      fontSize: theme.typography.pxToRem(24),
      color: 'grey',
    },
  })
