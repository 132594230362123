import { Theme, createStyles } from "@material-ui/core";

export const LabelStyles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    padding: 14,
    background: 'rgba(0,0,0,0.1)',
    borderRadius: theme.shape.borderRadius,
  },
  label: {
    marginRight: 7,
    // fontWeight: theme.typography.fontWeightMedium
  },
  value: {
    display: 'flex'
  }
})