import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, InputAdornment, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Button } from 'components/Button';
import { Dialog } from 'components/Dialog';
import { FileBox } from "components/FileBox";
import { RouteName } from 'enums/RouteName';
import useRoute from 'hooks/useRoute';
import { useSnackbar } from 'notistack';
import { DialogContext } from 'providers/dialog.provider';
import { FoodMenuContext } from 'providers/foodMenu.provider';
import { useCallback, useContext } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IFood } from 'types/food-menu.types';
import { amountTypes, formFoodSchema, privateTypes } from './Forms@config';
import { foodFormStyles } from './Forms@styles';

interface IFoodFormProps {
  foodDetail?: IFood
  onSubmit: (data: FieldValues) => void
  onRefresh?: () => void
}

const useStyles = makeStyles(foodFormStyles)

const FoodForm = (props: IFoodFormProps) => {
  const { onSubmit } = props

  // hooks
  const classes = useStyles()
  const { categoryId, foodId } = useParams<{ categoryId: string, foodId: string }>()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const route = useRoute()
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(formFoodSchema())
  })

  // context
  const { removeFoodFromCategory, updateFoodFromCategory } = useContext(FoodMenuContext)
  const { handleDialogAction } = useContext(DialogContext)

  const handlerSubmit = useCallback((data) => {
    reset()
    onSubmit(data)
  }, [onSubmit, reset])

  const handleRemoveFood = useCallback(() => {
    if (foodId) {
      removeFoodFromCategory(categoryId, foodId)
      route.push(`${RouteName.FoodMenu_Category}/${categoryId}`)
      enqueueSnackbar(
        t`Alert.Item_Was_Removed`,
        { variant: 'info' }
      )
    }
  }, [
    categoryId,
    foodId,
    removeFoodFromCategory,
    enqueueSnackbar,
    route,
    t
  ])

  const handleRemoveImage = useCallback(() => {
    if (props.foodDetail) {
      const data = { ...props.foodDetail, images: [] }
      updateFoodFromCategory(categoryId, foodId, data)
      props.onRefresh && props.onRefresh()
      enqueueSnackbar(
        t`Alert.Image_Was_Removed`,
        { variant: 'info' }
      )
    }
  }, [
    t,
    enqueueSnackbar,
    categoryId,
    foodId,
    updateFoodFromCategory,
    props
  ])

  return (
    <form
      onSubmit={handleSubmit(handlerSubmit)}
      className={classes.root}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t`Food_Menu.Name`}
                  variant='outlined'
                  defaultValue={props.foodDetail?.name || ''}
                  {...register('name')}
                  error={Boolean(errors?.name?.message)}
                  helperText={errors?.name?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  minRows={3}
                  multiline
                  fullWidth
                  defaultValue={props.foodDetail?.description || ''}
                  label={t`Food_Menu.Description`}
                  variant='outlined'
                  {...register('description')}
                />
              </Grid>
              {
                props.foodDetail?.images && props.foodDetail?.images?.length >= 1 && (
                  <Grid item xs={12}>
                    <Typography variant="h5" gutterBottom>{t`Global.Images`}</Typography>
                    <Grid container spacing={1}>
                      {
                        props.foodDetail?.images && props.foodDetail?.images.map(image => (
                          <Grid item xs={12} md={3}>
                            <FileBox
                              fileType='image'
                              onRemove={handleRemoveImage}
                              {...image}
                            />
                          </Grid>
                        ))
                      }
                    </Grid>
                  </Grid>
                )
              }
            </Grid>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  variant='outlined'
                  defaultValue={props.foodDetail?.state || 'private'}
                  {...register('state')}
                >
                  {privateTypes.map((d) => (
                    <MenuItem value={d.value} key={d.value}>
                      <Trans i18nKey={d.label} />
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t`Food_Menu.Price`}
                  variant='outlined'
                  type='number'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {t`Common.Currency.CZK`}
                      </InputAdornment>
                    ),
                  }}
                  defaultValue={props.foodDetail?.price || 0}
                  {...register('price')}
                  error={Boolean(errors?.price?.message)}
                  helperText={errors?.price?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={8}>
                    <TextField
                      fullWidth
                      label={t`Food_Menu.Amount`}
                      variant='outlined'
                      defaultValue={props.foodDetail?.amount}
                      {...register('amount')}
                      error={Boolean(errors?.amount?.message)}
                      helperText={errors?.amount?.message}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      select
                      variant='outlined'
                      defaultValue={props.foodDetail?.amountType || 'ml'}
                      {...register('amountType')}
                    >
                      {amountTypes.map((type) => (
                        <MenuItem value={type.value} key={type.value}>{type.label}</MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  type='submit'
                >
                  {t`Global.Save`}
                </Button>
              </Grid>
              {props.foodDetail && (
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    color='secondary'
                    onClick={handleDialogAction}
                  >
                    <Typography color="error">
                      {t`Food_Menu.Remove_Item`}
                    </Typography>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        title={t`Dialog.Remove`}
        contentText={t`Dialog.You_Are_About_To_Delete_An_Item`}
        onConfirm={handleRemoveFood}
        variant='error'
      />
    </form>
  )
}

export default FoodForm