import { Button, Grid, IconButton, Menu, MenuItem, MenuList, Tab, Typography, Zoom } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/styles';
import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { EmptyData } from 'components/EmptyData';
import { Food } from 'components/Food';
import { Spinner } from 'components/Spinner';
import { RouteName } from 'enums/RouteName';
import useRoute from 'hooks/useRoute';
import { FoodMenuContext } from 'providers/foodMenu.provider';
import { IFood } from 'types/food-menu.types';
import { categoryStyles } from './FoodMenu@styles';
import { ITabList, ITabPanels } from 'types/tablist.types';
import * as config from './FoodMenu@config'
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

interface ICategoryState {
  menu: null | HTMLElement
  value: string
}

const useStyles = makeStyles(categoryStyles)

const Category = () => {
  const [state, setState] = useState<ICategoryState>({
    menu: null,
    value: config.tabsValues.public
  })
  const { id } = useParams<{ id: string }>();
  const { getFoodsOfCategory, foods, isLoading } = useContext(FoodMenuContext)
  const { t } = useTranslation()
  const route = useRoute()
  const classes = useStyles()

  const open = Boolean(state.menu);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setState((prevState) => ({
      ...prevState,
      menu: event.currentTarget
    }))
  };

  const handleClose = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      menu: null
    }))
  }, [])

  useEffect(() => {
    const refresh = () => {
      getFoodsOfCategory(id)
    }

    refresh()
    // eslint-disable-next-line
  }, [id])

  const handleChangeRoute = useCallback(() => {
    route.push(`${RouteName.FoodMenu_InsertFood}/${id}`)
  }, [route, id])

  const handleEditCategory = useCallback(() => {
    route.push(`${RouteName.FoodMenu_EditCategory}/${id}`)
  }, [id, route])

  const handleChangeTab = useCallback((event: ChangeEvent<{}>, newValue: string) => {
    setState((prevState) => ({
      ...prevState,
      value: newValue
    }))
  }, [])

  const tabsList: ITabList[] = [
    {
      label: t`Food_Menu.Public`,
      value: config.tabsValues.public
    },
    {
      label: t`Food_Menu.Private`,
      value: config.tabsValues.private
    },
  ]

  const tabPanels: ITabPanels[] = [
    {
      value: config.tabsValues.public,
      content: (
        <Grid container spacing={2}>
          {foods.map((food: IFood, index) => {
            return food.state === 'public' && (
              <Grid key={index} item xs={12} md={3}>
                <Food {...food} />
              </Grid>
            )
          })}
        </Grid>
      )
    },
    {
      value: config.tabsValues.private,
      content: (
        <Grid container spacing={2}>
          {foods.map((food: IFood, index) => {
            return food.state === 'private' && (
              <Grid key={index} item xs={12} md={3}>
                <Food key={food.id} {...food} />
              </Grid>
            )
          })}
        </Grid>
      )
    }
  ]

  const DataContent = () => {
    if (isLoading) {
      return (<Spinner />)
    } else {
      return (
        <>
          {foods.length > 0 ? (
            <TabContext
              value={state.value}
            >
              <TabList
                onChange={handleChangeTab}
                textColor="primary"
                indicatorColor="primary"
              >
                {tabsList.map((tab, index) => (
                  <Tab key={index} label={tab.label} value={tab.value} />
                ))}
              </TabList>

              {tabPanels.map((tabPanel, index) => (
                <TabPanel
                  color="primary"
                  key={index}
                  value={tabPanel.value}
                  className={classes.tabPanel}
                >
                  {tabPanel.content}
                </TabPanel>
              ))}
            </TabContext>
          ) : (
            <div className={classes.emptyData}>
              <EmptyData>
                <Button
                  variant='outlined'
                  size='small'
                  color="primary"
                  onClick={handleChangeRoute}
                >
                  {t`Food_Menu.Add_Item`}
                </Button>
              </EmptyData>
            </div>
          )}
        </>
      )
    }
  }

  const MenuComponent = () => (
    <Menu
      id="menu"
      keepMounted
      anchorEl={state.menu}
      open={open}
      onClose={handleClose}
      TransitionComponent={Zoom}
    >
      <MenuList>
        <MenuItem onClick={handleChangeRoute}>
          <Typography variant="inherit">
            {t`Food_Menu.Add_Item`}
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleEditCategory}>
          <Typography variant="inherit">
            {t`Food_Menu.Edit_Category`}
          </Typography>
        </MenuItem>
      </MenuList>
    </Menu>
  )

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="h4">{t`Food_Menu.Title`}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={1} justifyContent='flex-end'>
              <Grid item>
                <IconButton
                  onClick={handleClick}
                  aria-haspopup="true"
                  aria-controls='menu'
                >
                  <MoreVertIcon fontSize='large' />
                </IconButton>

                <MenuComponent />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <DataContent />
      </Grid>
    </Grid >
  )
}

export default Category