import {
  Button,
  Grid
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MenusContext } from '../../providers/menus.provier';
import { IDailyMenus, IDailyMenuSoups } from '../../types/daily-menu.types';
import { menuDummyData, soupDummyData } from './DailyMenu@config';
import { DailyMenuDayProps } from './DailyMenu@types';
import DummySkeleton from './DummySkeleton';
import MenuSettings from './MenuSettings';
import Soup from './Soup';

const DailyMenuDay = (props: DailyMenuDayProps) => {
  // props
  const { documents } = props;

  // hooks
  const { t } = useTranslation();
  const {
    insertSoup,
    insertMenu,
    getMenus,
    getSoups,
    menus,
    soups,
    loading
  } = useContext(MenusContext);

  const handleAddSoup = useCallback(() => {
    return insertSoup(documents, soupDummyData);
  }, [insertSoup, documents]);

  const handleAddNewDailyMenu = useCallback(() => {
    return insertMenu(documents, menuDummyData);
  }, [insertMenu, documents]);

  useEffect(() => {
    function refresh() {
      getMenus(documents);
      getSoups(documents);
    }

    refresh()
    // eslint-disable-next-line
  }, [documents]);

  return (
    <div>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12}>
          {
            loading ? (
              <>
                <DummySkeleton />
              </>
            ) : soups.map((soup: IDailyMenuSoups, index) => (
              <Soup
                documents={documents}
                key={index}
                data={soup}
                index={index}
              />
            ))
          }
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="text"
            color="primary"
            size="large"
            fullWidth
            startIcon={<AddIcon />}
            onClick={handleAddSoup}
          >
            {t('Daily_Menu.Add_Soup')}
          </Button>
        </Grid>

        <Grid item xs={12}>
          {
            loading ? (
              <>
                <DummySkeleton />
                <DummySkeleton />
              </>
            ) : menus.map((menu: IDailyMenus, index: number) => (
              <MenuSettings
                documents={documents}
                key={index}
                data={menu}
                index={index}
              />
            ))
          }
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="text"
            color="primary"
            size="large"
            fullWidth
            startIcon={<AddIcon />}
            onClick={handleAddNewDailyMenu}
          >
            {t`Daily_Menu.Add_Menu`}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default DailyMenuDay;
