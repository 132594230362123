import { Theme, createStyles } from "@material-ui/core";

export const flagStyles = (theme: Theme) => createStyles({
  root: {
    backgroundSize: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    display: 'inline-block',
    margin: 0,
    padding: 0
  }
})