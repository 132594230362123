import { Typography } from '@material-ui/core'
import { TitleProps } from './Title@types'

const Title = (props: TitleProps) => {
  const { children } = props

  return (
    <Typography component="h2" variant="h6" color="primary" gutterBottom>
      {children}
    </Typography>
  )
}

export default Title
