import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { makeStyles } from '@material-ui/styles'
import classnames from 'classnames'
import { ChangeEvent, MouseEvent, useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MenusContext } from '../../providers/menus.provier'
import { IDailyMenuSoups } from '../../types/daily-menu.types'
import { partsStyles } from './DailyMenu@styles'

interface ISoupProps {
  documents: string
  data: IDailyMenuSoups
  index: number
}

const useStyles = makeStyles(partsStyles)

const Soup = (props: ISoupProps) => {
  // props
  const { data, index, documents } = props

  // state
  const [unsave, setUnsave] = useState<boolean>(false)
  const [submenu, setSubmenu] = useState<null | HTMLElement>(null)
  const [state, setState] = useState<IDailyMenuSoups>({
    isSoupActive: data.isSoupActive,
    soupName: data.soupName,
    soupPrice: data.soupPrice,
    soupAmount: data.soupAmount,
    soupAllergens: data.soupAllergens,
  })

  // hooks
  const { t } = useTranslation()
  const classes = useStyles()
  const { removeSoup, updateSoup } = useContext(MenusContext)
  const soupId = data?.id || ''
  const activatedClasses = state.isSoupActive ? classnames([classes.paper, classes.activated]) : classes.paper

  /**
   * If is allergen in state, system detect active allergen
   * @param identifier
   * @returns
   */
  // const checkActiveAllergen = (identifier: string): boolean => {
  //   const arr: string[] = [...state.soupAllergens]
  //   return arr.includes(identifier) || false
  // }

  /**
   * If is active allergen and user click on allergen again,
   * system will remove that allergen from state so result is
   * current active allergens. Also if user click on unactive allergen
   * system will add that allergen to state and results is active allergens
   * @param identifier
   * @returns
   */
  // const handleAddAllergen = (identifier: string): void => {
  //   setUnsave(true)
  //   if (checkActiveAllergen(identifier)) {
  //     return setState((prevState) => ({
  //       ...prevState,
  //       soupAllergens: state.soupAllergens.filter((item) => {
  //         return item !== identifier
  //       }),
  //     }))
  //   } else {
  //     return setState((prevState) => ({
  //       ...prevState,
  //       soupAllergens: [...state.soupAllergens, identifier],
  //     }))
  //   }
  // }

  const handleUpdateSoup = useCallback(() => {
    setUnsave(false)
    return updateSoup(soupId, documents, state)
  }, [soupId, documents, state, updateSoup])

  const handleRemoveSoup = useCallback(async () => {
    await removeSoup(soupId, documents)
  }, [soupId, documents, removeSoup])

  /**
   * Field text changer
   * @param event
   * @returns
   */
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setUnsave(true)
    return setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }))
  }, [])

  const handleActiveSoup = useCallback(() => {
    const data = {
      isSoupActive: !state.isSoupActive,
    }
    setState((prevState) => ({
      ...prevState,
      isSoupActive: !state.isSoupActive,
    }))
    return updateSoup(soupId, documents, data)
  }, [soupId, documents, state.isSoupActive, updateSoup])

  const handleOpenSubmenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setSubmenu(event.currentTarget)
  }, [])

  const handleCloseSubmenu = useCallback(() => {
    return setSubmenu(null)
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper className={activatedClasses}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="overline" gutterBottom>
                {`${t('Daily_Menu.Soup')} ${index + 1}`}
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.right}>
              <Switch name="isSoupActive" checked={state.isSoupActive} onChange={handleActiveSoup} color="primary" />
              <IconButton size="small" onClick={handleOpenSubmenu}>
                <MoreVertIcon />
              </IconButton>
              <Menu id="soup-menu" anchorEl={submenu} keepMounted open={Boolean(submenu)} onClose={handleCloseSubmenu}>
                <MenuItem onClick={handleRemoveSoup}>
                  <Typography variant="inherit" color="secondary">
                    {t('Global.Remove')}
                  </Typography>
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
          <div className={classes.spacing}>
            <Grid container spacing={1}>
              <Grid item md={2} xs={12}>
                <TextField
                  id="soupAmount"
                  name="soupAmount"
                  label={t`Daily_Menu.Amount`}
                  variant="outlined"
                  value={state.soupAmount}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" className={classes.lowercase}>
                        {t('Global.MiliLitres_Short')}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item md={8} xs={12}>
                <TextField
                  id="soupName"
                  name="soupName"
                  label={t`Daily_Menu.Soup_Name`}
                  variant="outlined"
                  value={state.soupName}
                  onChange={handleChange}
                  fullWidth
                />
              </Grid>

              <Grid item md={2} xs={12}>
                <TextField
                  id="soupPrice"
                  name="soupPrice"
                  label={t`Daily_Menu.Price`}
                  variant="outlined"
                  value={state.soupPrice}
                  onChange={handleChange}
                  type="number"
                  fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{t('Common.Currency.CZK')}</InputAdornment>,
                  }}
                />
              </Grid>

              <Grid item md={10} xs={12} />

              <Grid item md={2} xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={!unsave}
                  onClick={handleUpdateSoup}
                  fullWidth
                >
                  {t('Global.Save')}
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Soup
