import { Theme, createStyles, colors } from '@material-ui/core'

export const notificationsStyles = (theme: Theme) =>
  createStyles({
    root: {},
    menu: {
      minWidht: 350,
      boxShadow: theme.shadows[20]
    },
  })

export const notificationStyles = (theme: Theme) =>
  createStyles({
    root: {
      minWidth: 350,
      minHeight: 60,
      margin: theme.spacing(1),
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      backgroundColor: colors.blue[500],
    },
    content: {
      display: 'flex',
      alignItems: 'center',
    },
    dots: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  })
