import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, MenuItem, Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Button } from 'components/Button';
import { Dialog } from 'components/Dialog';
import { RouteName } from 'enums/RouteName';
import useRoute from 'hooks/useRoute';
import { useSnackbar } from 'notistack';
import { DialogContext } from 'providers/dialog.provider';
import { FoodMenuContext } from 'providers/foodMenu.provider';
import { useCallback, useContext } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { formCategorySchema, privateTypes } from './Forms@config';
import { categoryFormStyles } from './Forms@styles';

interface IFoodFormProps {
  categoryDetail?: any
  onSubmit: (data: FieldValues) => void
}

const useStyles = makeStyles(categoryFormStyles)

const CategoryForm = (props: IFoodFormProps) => {
  const { onSubmit } = props

  // hooks
  const classes = useStyles()
  const route = useRoute()
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { removeCategory } = useContext(FoodMenuContext)
  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(formCategorySchema())
  })

  // context
  const { handleDialogAction } = useContext(DialogContext)

  const handlerSubmit = useCallback((data) => {
    reset()
    onSubmit(data)
  }, [onSubmit, reset])

  const handleRemoveCategory = useCallback(() => {
    removeCategory(id);
    route.push(RouteName.FoodMenu)
    enqueueSnackbar(
      t`Alert.Category_Was_Removed`,
      { variant: 'info' }
    )
  }, [enqueueSnackbar, id, removeCategory, route, t])

  return (
    <form
      className={classes.root}
      onSubmit={handleSubmit(handlerSubmit)}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} md={8}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t`Food_Menu.Cs_Name`}
                  variant='outlined'
                  defaultValue={props?.categoryDetail?.csName || ''}
                  {...register('csName')}
                  error={Boolean(errors?.csName?.message)}
                  helperText={errors?.csName?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t`Food_Menu.En_Name`}
                  variant='outlined'
                  defaultValue={props?.categoryDetail?.enName || ''}
                  {...register('enName')}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className={classes.paper}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  select
                  variant='outlined'
                  defaultValue={props?.categoryDetail?.state || 'private'}
                  {...register('state')}
                >
                  {privateTypes.map((d) => (
                    <MenuItem value={d.value} key={d.value}>
                      <Trans i18nKey={d.label} />
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  type='submit'
                >
                  {t`Global.Save`}
                </Button>
              </Grid>
              {props.categoryDetail && (
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    onClick={handleDialogAction}
                  >
                    <Typography color="error">
                      {t`Food_Menu.Remove_Category`}
                    </Typography>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Dialog
        title={t`Dialog.Remove`}
        contentText={t`Dialog.You_Are_About_To_Delete_An_Category`}
        onConfirm={handleRemoveCategory}
        variant='error'
      />
    </form>
  )
}

export default CategoryForm