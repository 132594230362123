import { createStyles, Theme } from '@material-ui/core'
import { LayoutStylesPropsTypes } from './Layout@types'

export const LayoutStyles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      maxHeight: '100vh',
      overflow: 'auto',
    },
    toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
      flexGrow: 1,
      alignSelf: 'flex-end',
      width: '100%',
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      boxShadow: 'none',
      backgroundColor: theme.palette.background.default,
      flexGrow: 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShiftMobile: {
      marginLeft: 0,
      width: '100%',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    appBarShift: (props: LayoutStylesPropsTypes) => ({
      marginLeft: props.getSiderNavWidth,
      width: `calc(100% - ${props.getSiderNavWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    menuButtonWrapper: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: 36,
      alignSelf: 'flex-start',
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      // flexGrow: 1,
    },
    drawerPaper: (props: LayoutStylesPropsTypes) => ({
      position: 'relative',
      whiteSpace: 'nowrap',
      minWidth: props.getSiderNavWidth,
      width: props.getSiderNavWidth,
      backgroundColor: theme.palette.background.default,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      position: 'relative',
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
    fixedHeight: {
      height: 240,
    },
    logo: {
      margin: theme.spacing(4),
      textAlign: 'center',
    },
    profile: (props: LayoutStylesPropsTypes) => ({
      maxWidth: props.getSiderNavWidth - theme.spacing(4),
      margin: theme.spacing(2),
      marginTop: 0,
    }),
    profileCard: {
      padding: theme.spacing(2),
    },
    profileCardAvatar: {
      display: 'flex',
      alignItems: 'center',
    },
  })
