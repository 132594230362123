import { Documents } from 'remote/Collections'
import { IOpeningCard } from './OpeningHours@types'

export const tabsValues = {
  monday: 'monday',
  tuesday: 'tuesday',
  wednesday: 'wednesday',
  thursday: 'thursday',
  friday: 'friday',
  saturtday: 'saturday',
  sunday: 'sunday',
}

export const OpeningHoursDocuments = {
  monday: Documents.Monday,
  tuesday: Documents.Tuesday,
  wednesday: Documents.Wednesday,
  thursday: Documents.Thursday,
  friday: Documents.Friday,
  saturday: Documents.Saturday,
  sunday: Documents.Sunday,
}

export const openingCards: IOpeningCard[] = [
  {
    id: '1',
    title: 'Opening_Hours.Restaurant',
    place: 'restaurant'
  },
  {
    id: '2',
    title: 'Opening_Hours.Hotel',
    place: 'hotel'
  },
  {
    id: '3',
    title: 'Opening_Hours.Daily_Menu',
    place: 'daily-menu'
  },
  {
    id: '4',
    title: 'Opening_Hours.Reception',
    place: 'reception'
  },
]
