import { createStyles, Theme } from '@material-ui/core'

export const AvatarStyles = (theme: Theme) =>
  createStyles({
    root: {},
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    menu: {},
    profile: {
      minWidth: 220,
      padding: theme.spacing(2),
    },
    menuItems: {
      marginTop: theme.spacing(1),
    },
    menuItem: {
      '& svg': {
        marginRight: theme.spacing(2),
      },
    },
    logoutButton: {
      width: '100%',
      padding: theme.spacing(2),
    },
  })
