import { Documents } from '../../remote/Collections'
import { IDailyMenuInfoNew, IDailyMenus, IDailyMenuSoups } from '../../types/daily-menu.types'

export const tabsValues = {
  monday: 'monday',
  tuesday: 'tuesday',
  wednesday: 'wednesday',
  thursday: 'thursday',
  friday: 'friday',
  saturtday: 'startday',
  sunday: 'sunday',
}

export const dailyMenuFieldNames = {
  date: 'date',
  description: 'description',
  isCanceled: 'isCanceled',
  reasonForCancel: 'reasonForCancel',
  name: 'name',
  amount: 'amount',
  price: 'price',
  allergens: 'allergens',
  isBusiness: 'isBusiness',
  number: 'number',
}

// Documents
export const DailyMenuDocuments = {
  monday: Documents.Monday,
  tuesday: Documents.Tuesday,
  wednesday: Documents.Wednesday,
  thursday: Documents.Thursday,
  friday: Documents.Friday,
  saturday: Documents.Saturday,
  sunday: Documents.Sunday,
}

export const soupDummyData: IDailyMenuSoups = {
  isSoupActive: false,
  soupName: '',
  soupAmount: 0,
  soupPrice: '',
  soupAllergens: [],
}

export const menuDummyData: IDailyMenus = {
  menuName: '',
  menuPrice: '',
  menuAmount: 0,
  menuAllergens: [],
  isMenuActive: false,
  isMinute: false,
  isBusiness: false,
}

export const infoDummyData: IDailyMenuInfoNew = {
  menuDate: 0,
  lastUpdate: 0,
  reasonForCancel: '',
  extraText: '',
  isCanceled: false,
}
