import { initYup } from '../../plugins/yup'
import i18n from '../../services/i18n'
import t from 'i18next'

export const createFormChangePasswordSchema = () => {
  const yup = initYup(i18n.language)

  return yup.object({
    newPassword: yup
      .string()
      .required()
      .label(t.t`Settings.New_Password`),
    confirmNewPassword: yup
      .string()
      .required()
      .label(t.t`Settings.Confirm_New_Password`),
  })
}

export const createFormPersonalInformationsSchema = () => {
  const yup = initYup(i18n.language)

  return yup.object({
    displayName: yup
      .string()
      .required()
      .label(t.t`Settings.Name_Surname`),
    email: yup.string().label(t.t`Settings.Email`),
    phone: yup.string().label(t.t`Settings.Phone`),
  })
}
