import {
  Grid,
  MenuItem,
  Paper,
  Select,
  Switch, Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Flag } from 'components/Flag'
import { languages } from "configs"
import { AppContext } from "providers/app.provider"
import { useContext, useState } from "react"
import { Trans, useTranslation } from 'react-i18next'
import { LanguageTypes } from "types/languages.types"
import { settingsPartsStyles } from "../Settings@styles"
import { GeneralSettingsStateTypes } from "../Settings@types"

const useStyles = makeStyles(settingsPartsStyles)

const General = () => {
  const [state, setState] = useState<GeneralSettingsStateTypes>({
    openLanguageSelect: false,
    selectedLanguage: null,
  })

  const { openLanguageSelect } = state

  // context
  const { setThemeMode, getThemeMode, getLanguage, setLanguage } =
    useContext(AppContext)

  // hooks
  const classes = useStyles()
  const { t } = useTranslation()

  const theme = String(getThemeMode())
  const mode = theme === 'dark' ? true : false
  const handleSetDarkMode = () => {
    setThemeMode()
  }

  // LNAGUAGE SETTINGS
  const handleActionsLanguageSelect = () => {
    if (openLanguageSelect) {
      setState((prevState) => ({
        ...prevState,
        openLanguageSelect: false,
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        openLanguageSelect: true,
      }))
    }
  }

  return (
    <div className={classes.generalSettingsRoot}>
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="overline" gutterBottom>
              {t`Settings.View`}
            </Typography>
            <div className={classes.spacing}>
              <Grid container spacing={1}>
                <Grid item xs={4} className={classes.middle}>
                  <Typography variant="body2">{t`Settings.Dark_Mode`}</Typography>
                </Grid>
                <Grid item xs className={classes.right}>
                  <Switch
                    checked={mode}
                    color="primary"
                    onChange={handleSetDarkMode}
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>

          <Paper className={classes.paper}>
            <Typography variant="overline" gutterBottom>
              {t`Settings.Preference`}
            </Typography>
            <div className={classes.spacing}>
              <Grid container spacing={1}>
                <Grid item xs={4} className={classes.middle}>
                  <Typography variant="body2">{t`Global.Language`}</Typography>
                </Grid>
                <Grid item xs>
                  <Select
                    className={classes.fullWidth}
                    variant="outlined"
                    color="primary"
                    id="language-select"
                    labelId="language-select"
                    value={getLanguage}
                    open={openLanguageSelect}
                    onOpen={handleActionsLanguageSelect}
                    onClose={handleActionsLanguageSelect}
                    onChange={(e) => setLanguage(String(e.target.value))}
                  >
                    {languages.map((language: LanguageTypes) => (
                      <MenuItem key={language.key} value={language.value}>
                        <div className={classes.menuItem}>
                          <Flag code={language.flag} spacing={12} height={20} />
                          <Trans i18nKey={language.label} />
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
        <Grid item xs />
      </Grid>
    </div>
  )
}

export default General