import { makeStyles, Typography } from '@material-ui/core'
import { FoodForm } from 'components/Forms'
import { Spinner } from 'components/Spinner'
import { useSnackbar } from 'notistack'
import { FoodMenuContext } from 'providers/foodMenu.provider'
import { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { editMenuStyles } from './FoodMenu@styles'

const useStyles = makeStyles(editMenuStyles)

const EditFood = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { categoryId, foodId } = useParams<{ categoryId: string, foodId: string }>()
  const { getDetailOfFood, detailOfFood, updateFoodFromCategory, isLoading } = useContext(FoodMenuContext)
  const { enqueueSnackbar } = useSnackbar()

  const handleRefresh = useCallback(() => {
    return getDetailOfFood(categoryId, foodId)
  }, [categoryId, foodId, getDetailOfFood])

  useEffect(() => {
    const refresh = () => {
      return getDetailOfFood(categoryId, foodId)
    }

    refresh()
    // eslint-disable-next-line
  }, [categoryId, foodId])

  const onSubmit = useCallback((data) => {
    updateFoodFromCategory(categoryId, foodId, data)
    getDetailOfFood(categoryId, foodId)
    enqueueSnackbar(
      t`Alert.Item_Was_Updated`,
      { variant: 'info' }
    )
  }, [categoryId, foodId, enqueueSnackbar, updateFoodFromCategory, t, getDetailOfFood])

  return (
    <div className={classes.root}>
      <Typography gutterBottom variant='h4'>
        {t`Food_Menu.Edit_Item`}
      </Typography>
      {
        isLoading
          ? <Spinner />
          : (
            detailOfFood && <FoodForm
              onSubmit={onSubmit}
              foodDetail={detailOfFood}
              onRefresh={handleRefresh}
            />
          )
      }
    </div >
  )
}

export default EditFood