import { createStyles, Theme } from '@material-ui/core'
import { CSSProperties } from 'react'
import { ButtonStylesProps } from './Button@types'
import { ButtonProps } from './Button@types'

export const ButtonStyles = (theme: Theme) =>
  createStyles({
    root: (props: ButtonStylesProps) => ({
      fontSize: props.size === 'small' ? '0.75rem' : '1rem',
      [theme.breakpoints.up('sm')]: {
        fontSize: '1rem',
      },
    }),
    label: (props: ButtonStylesProps) => ({
      display: 'flex',
      flexDirection: props.direction || 'row',
      alignItems: 'center',
      justifyContent: props.justifyContent || 'center',
      '& svg:last-child': {
        marginLeft: 8,
      },
    }),
  })

export const createInlineStyles = (props: Partial<ButtonProps>) => {
  const {
    textTransform,
    paddingX,
    paddingY,
    marginX = 'auto',
    marginY = 0,
    marginTop,
    marginBottom,
    textAlign,
    maxWidth = 'none',
    minWidth,
    borderSize,
  } = props

  // create styles
  const styles: CSSProperties = {
    textAlign,
    maxWidth,
  }

  if (borderSize) {
    styles.borderStyle = 'solid'
    styles.borderWidth = borderSize
    styles.borderColor = 'inherit'
    styles.boxSizing = 'border-box'
  }

  if (marginX !== undefined && marginX !== false) {
    styles.marginLeft = marginX
    styles.marginRight = marginX
  }

  if (marginY !== undefined && marginY !== false) {
    styles.marginTop = marginY
    styles.marginBottom = marginY
  }

  if (textTransform !== undefined) {
    styles.textTransform = textTransform
  }

  if (minWidth !== undefined) {
    styles.minWidth = minWidth
  }

  // add X padding
  if (paddingX !== undefined) {
    styles.paddingLeft = paddingX
    styles.paddingRight = paddingX
  }

  // add X padding
  if (paddingY !== undefined) {
    styles.paddingTop = paddingY
    styles.paddingBottom = paddingY
  }

  // add TOP margin
  if (marginTop !== undefined) {
    styles.marginTop = marginTop
  }

  // add BOTTOM margin
  if (marginBottom !== undefined) {
    styles.marginBottom = marginBottom
  }

  return styles
}
