import { makeStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'
import { UserCard } from '../../components/UserCard'
import { users } from './_temp'
import { usersStyles } from './Users@styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(usersStyles)

const Users = () => {
  // hooks
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div>
      <div className={classes.header}>
        <Typography variant="h5" gutterBottom>
          {t`Users.Header`}
        </Typography>
      </div>
      <Grid container spacing={4}>
        {users.map((user) => (
          <Grid key={user.id} item md={4} xs={12}>
            <UserCard {...user} />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default Users
