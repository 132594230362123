import { makeStyles, Typography } from '@material-ui/core'
import dayjs from 'dayjs'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Table } from '../../components/Table'
import { TableCheckIcon } from '../../components/Table/Table@parts'
import { DataContext } from '../../providers/data.provider'
import { Collections, Documents } from '../../remote/Collections'
import { formatIsoDate } from '../../utils/datetime'
import expiringDate from '../../utils/datetime/expiring-date'
import { DomainsStyles } from './Domains@styles'

const useStyles = makeStyles(DomainsStyles)

const Domains = () => {
  //state
  const [state, setState] = useState({
    loading: true,
  })
  const { loading } = state

  // init
  const collection = Collections.WebManagement
  const documents = Documents.Domains

  // hooks
  const { t } = useTranslation()
  const classes = useStyles()

  // context
  const { get, data, update, insert, remove } = useContext(DataContext)

  const columns = [
    {
      title: t`Domains.Domain`,
      field: 'domain',
    },
    {
      title: t`Domains.Operator`,
      field: 'operator',
    },
    {
      title: t`Domains.Hosted`,
      field: 'hosted',
    },
    {
      title: t`Domains.Email`,
      field: 'email',
    },
    {
      title: t`Domains.Phone`,
      field: 'phone',
    },
    {
      title: t`Domains.Paid`,
      field: 'isPaid',
      lookup: { true: t`Global.Is_Paid`, false: t`Global.Is_Not_Paid` },
      render: (rowData: any) => (
        <TableCheckIcon rowItem={rowData.isPaid} />
      ),
    },
    {
      title: t`Domains.Expiration`,
      field: 'expiration',
      render: (rowData: any) => (
        <Typography
          variant="body2"
          className={expiringDate(rowData.expiration) ? classes.dangerText : ''}
        >
          {formatIsoDate(rowData.expiration)}
        </Typography>
      ),
    },
  ]

  useEffect(() => {
    const refresh = async () => {
      await get({ collection, documents })
      await setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }))
      }, 100)
    }

    refresh()
    // eslint-disable-next-line
  }, [collection, documents])

  return (
    <div className={classes.root}>
      <Table
        title={t`Domains.Header`}
        columns={columns}
        data={data}
        isLoading={loading}
        editable={{
          onRowAdd: (newData: any) =>
            new Promise(async (resolve, reject) => {
              const controlBoolean = newData.isPaid === 'true' ? true : false
              const data = {
                ...newData,
                isPaid: controlBoolean,
                expiration: dayjs(newData.expiration).format('YYYY-MM-DD')
              }
              await insert({ collection, documents, data })
              resolve(null)
            }),
          onRowDelete: async (oldData: any) =>
            new Promise(async (resolve, reject) => {
              await remove({ collection, documents, id: oldData.id })
              resolve(null)
            }),
          onRowUpdate: (newData: any) =>
            new Promise(async (resolve, reject) => {
              const controlBoolean = newData.isPaid === 'true' ? true : false
              const data = {
                ...newData,
                isPaid: controlBoolean
              }
              await update({ collection, documents, id: newData.id, data })
              resolve(null)
            })
        }}
      />
    </div>
  )
}

export default Domains
