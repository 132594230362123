import { createStyles, Theme } from '@material-ui/core'

export const DomainsStyles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    modal: {
      maxWidth: 400,
    },
    dangerText: {
      color: 'red',
      // fontWeight: theme.typography.fontWeightBold
    }
  })
