import { Theme, createStyles } from '@material-ui/core'

export const openingHoursStyles = (theme: Theme) =>
  createStyles({
    root: {},
    tabPanel: {
      padding: theme.spacing(3, 0),
    },
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      height: 'auto',
      border: '1px solid',
      borderColor: 'transparent',
    },
  })

export const openingHoursDay = (theme: Theme) =>
  createStyles({
    root: {},
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
      height: 'auto',
      border: '1px solid',
      borderColor: 'transparent',
    },
  })
