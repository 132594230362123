import { ReactNode, useContext } from 'react';
import { themeDark, themeLight } from '../theme';
import { AppContext } from './app.provider';

import { Themes } from '../styles/Styles@types';
import { ThemeProvider } from '@material-ui/styles';

import AuthProvider from './auth.provider';
import DataProvider from './data.provider';
import UserProvider from './user.provider';
import AlertProvider from './alerts.provider';
import NotificationsProvider from './nofitications.provider';
import MenusProvider from './menus.provier';
import FoodMenuProvider from './foodMenu.provider'
import DialogProvider from './dialog.provider'
import UploadProvider from './upload.provider'

type MasterProviderProps = {
  children: ReactNode;
};

const MasterProvider = (props: MasterProviderProps) => {
  const { children } = props;

  const { getThemeMode } = useContext(AppContext);
  const theme = String(getThemeMode());

  const themeConfig = theme === Themes.dark ? themeDark : themeLight;

  return (
    <AuthProvider>
      <UserProvider>
        <DataProvider>
          <AlertProvider>
            <NotificationsProvider>
              <MenusProvider>
                <FoodMenuProvider>
                  <DialogProvider>
                    <UploadProvider>
                      <ThemeProvider theme={themeConfig}>{children}</ThemeProvider>
                    </UploadProvider>
                  </DialogProvider>
                </FoodMenuProvider>
              </MenusProvider>
            </NotificationsProvider>
          </AlertProvider>
        </DataProvider>
      </UserProvider>
    </AuthProvider>
  );
};

export default MasterProvider;
