import { ReactNode } from 'react'
import { createContext } from 'react'
import {
  NotificationsModel,
  NotificationTypes,
} from '../models/notifications.model'

type Context = {
  notifications: any
  notificationsCount: number
}

type NotificationsProviderProps = {
  children: ReactNode
}

export const NotificationsContext = createContext<Context>(null as any)

const NotificationsProvider = (props: NotificationsProviderProps) => {
  // state

  // props
  const { children } = props

  // hooks

  // _TEMP
  const notifications: NotificationsModel[] = [
    {
      id: '1',
      type: NotificationTypes.Question,
      title: 'Nový dotaz!',
      created_at: 1626027944,
      hasRead: true,
    },
    {
      id: '2',
      type: NotificationTypes.Order,
      title: 'Nová objednávka!',
      created_at: 1626023944,
      hasRead: false,
    },
    {
      id: '3',
      type: NotificationTypes.Task,
      title: 'Nový úkol!',
      created_at: 1626020944,
      hasRead: false,
    },
    {
      id: '4',
      type: NotificationTypes.Order,
      title: 'Nová objednávka!',
      created_at: 1626027544,
      hasRead: false,
    },
  ]

  const notificationsCount = notifications.length

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        notificationsCount,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export default NotificationsProvider
