import { createStyles, Theme } from '@material-ui/core'

export const UserProfileStyles = (theme: Theme) =>
  createStyles({
    root: {},
    paper: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
  })
