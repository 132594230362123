export enum AlertTypes {
  success = 'success',
  error = 'error',
  info = 'info',
  warning = 'warning',
}

export interface IAlert {
  id: string
  title?: string | undefined
  description: string
  type: AlertTypes
}
