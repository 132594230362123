import { LanguageTypes } from "../types/languages.types"

const languages: LanguageTypes[] = [
  {
    key: 'english',
    label: 'Global.English',
    value: 'en',
    flag: 'gb'
  },
  {
    key: 'czech',
    label: 'Global.Czech',
    value: 'cs',
    flag: 'cz'
  }
]

export default languages