import { createContext, ReactNode, useState } from 'react'
import { SubCollections } from '../remote/Collections'
import firebase from '../remote/Firebase'
import {
  FirebaseRootTypes,
  GetRecordTypes,
  InsertTypes,
  RemoveTypes,
  UpdateTypes,
} from '../types/providers.types'

type Context = {
  getRootData: ({ collection, documents }: FirebaseRootTypes) => void
  get: ({ collection, documents }: FirebaseRootTypes) => void
  getRecord: ({ collection, documents, id }: GetRecordTypes) => void
  getFiledData: ({ collection, documents }: FirebaseRootTypes) => void
  insertField: ({ collection, documents, data }: InsertTypes) => void
  insert: ({ collection, documents, data }: InsertTypes) => void
  update: ({ collection, documents, id, data }: UpdateTypes) => void
  remove: ({ collection, documents, id }: RemoveTypes) => void
  isLoading: boolean
  data: object[]
  fieldData: any | undefined
  record: object | undefined
}

type DataProviderPropsTypes = {
  children: ReactNode
}

export const DataContext = createContext<Context>(null as any)

const DataProvider = (props: DataProviderPropsTypes) => {
  // state
  const [data, setData] = useState<object[]>([])
  const [fieldData, setFieldData] = useState<object | undefined>(undefined)
  const [record, setRecord] = useState<object | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  // props
  const { children } = props

  // settings
  const dataCollection = SubCollections.Data
  const db = firebase.firestore()

  const cleaningState = () => {
    setData([])
    setFieldData({})
    setRecord({})
    setIsLoading(true)
  }

  /**
   * Get field data in root documents
   * @param collection collection in firestore db
   * @param documents document in firestore db
   */
  const getFiledData = async ({
    collection,
    documents,
    subCollection,
  }: FirebaseRootTypes) => {
    if (subCollection) {
      cleaningState()
      await db
        .collection(collection)
        .doc(documents)
        .collection(subCollection)
        .onSnapshot((s) => {
          const listItems = s.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          setFieldData(listItems)
          setIsLoading(false)
          return listItems
        })
    } else {
      await db
        .collection(collection)
        .doc(documents)
        .get()
        .then((response) => {
          setFieldData(response.data())
          setIsLoading(false)
        })
    }
  }

  /**
   * Get root data from firebase
   * @param collection collection in firestore db
   * @param documents document in firestore db
   */
  const getRootData = async ({
    collection,
    documents
  }: FirebaseRootTypes) => {
    cleaningState()
    await db
      .collection(collection)
      .doc(documents)
      .get()
      .then(response => {
        setRecord(response.data())
      })
      .catch((err) => {
        console.error(err)
      })
  }

  /**
   * Get all records in collection
   * @param collection collection in firestore db
   * @param documents document in firestore db
   */
  const get = async ({
    collection,
    documents,
    subCollection,
    orderBy
  }: FirebaseRootTypes) => {
    cleaningState()
    if (orderBy) {
      await db
        .collection(collection)
        .doc(documents)
        .collection(subCollection || dataCollection)
        .orderBy(orderBy)
        .onSnapshot((s) => {
          const listItems = s.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          setData(listItems)
          setIsLoading(false)
          return listItems
        })
    } else {
      await db
        .collection(collection)
        .doc(documents)
        .collection(subCollection || dataCollection)
        .onSnapshot((s) => {
          const listItems = s.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          setData(listItems)
          setIsLoading(false)
          return listItems
        })
    }
  }

  /**
   * Get one record by table
   * @param collection collection in firestore db
   * @param documents document in firestore db
   * @param id id in firestore db
   */
  const getRecord = async ({
    collection,
    documents,
    subCollection,
    id,
  }: GetRecordTypes) => {
    cleaningState()
    await db
      .collection(collection)
      .doc(documents)
      .collection(subCollection || dataCollection)
      .doc(id)
      .get()
      .then((response) => {
        setRecord(response.data())
        setIsLoading(false)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  /**
   * Add new record to collection
   * @param collection collection in firestore db
   * @param documents document in firestore db
   * @param data object with new record
   */
  const insertField = async ({ collection, documents, data }: InsertTypes) => {
    await db.collection(collection).doc(documents).set(data)
  }

  /**ƒ
   * Add new record to collection
   * @param collection collection in firestore db
   * @param documents document in firestore db
   * @param data object with new record
   */
  const insert = async ({
    collection,
    documents,
    subCollection,
    data,
  }: InsertTypes) => {
    await db
      .collection(collection)
      .doc(documents)
      .collection(subCollection || dataCollection)
      .add(data)
  }

  /**
   * Remove record in collection
   * @param collection collection in firestore db
   * @param documents document in firestore db
   * @param data object where is changes
   * @param id id in firestore db
   */
  const update = async ({
    collection,
    documents,
    subCollection,
    id,
    data,
  }: UpdateTypes) => {
    await db
      .collection(collection)
      .doc(documents)
      .collection(subCollection || dataCollection)
      .doc(id)
      .update(data)
  }

  /**
   * Remove record in collection
   * @param collection collection in firestore db
   * @param documents document in firestore db
   * @param id id in firestore db
   */
  const remove = async ({
    collection,
    documents,
    subCollection,
    id,
  }: RemoveTypes) => {
    await db
      .collection(collection)
      .doc(documents)
      .collection(subCollection || dataCollection)
      .doc(id)
      .delete()
  }

  return (
    <DataContext.Provider
      value={{
        getRootData,
        get,
        getRecord,
        getFiledData,
        insertField,
        insert,
        update,
        remove,
        fieldData,
        data,
        record,
        isLoading
      }}
    >
      {children}
    </DataContext.Provider>
  )
}

export default DataProvider
