import { Card, CardHeader, Chip, Grid, makeStyles } from '@material-ui/core';
import classnames from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteName } from '../../enums/RouteName';
import useRoute from '../../hooks/useRoute';

import { CategoryStyles } from './Category@styles';
import { ICategoryProps } from './Category@types';

const useStyles = makeStyles(CategoryStyles)

const Category = (props: ICategoryProps) => {
  const classes = useStyles()
  const route = useRoute()
  const { t } = useTranslation()

  const isPublic = props.state === 'public'

  const handleClick = useCallback(() => {
    route.push(`${RouteName.FoodMenu_Category}/${props.id}`)
  }, [route, props.id])

  return (
    <Grid item xs={12} className={classes.root}>
      <Card
        onClick={handleClick}
        className={classnames([classes.category, classes.pointer])}
      >
        <CardHeader
          title={props.csName}
          subheader={(
            <Grid container spacing={1}>
              <Grid item xs={12} />
              <Grid item xs={12}>
                <Chip
                  label={isPublic ? t`Global.Public` : t`Global.Private`}
                  variant='outlined'
                  size='small'
                  color={isPublic ? 'primary' : 'secondary'}
                />
              </Grid>
            </Grid>
          )}
        />
      </Card>
    </Grid>
  )
}

export default Category