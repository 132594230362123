import { makeStyles } from "@material-ui/styles"
import { Close as CloseIcon, Done as DoneIcon } from '@material-ui/icons'
import { CheckerStyles } from "./Checker@styles"
import { CheckerPropsTypes } from "./Checker@types"

const useStyles = makeStyles(CheckerStyles)

const Checker = (props: CheckerPropsTypes) => {
  const { checker } = props

  // hooks
  const classes = useStyles()

  if (checker) {
    return <DoneIcon className={classes.greenIcon} />
  }

  return <CloseIcon className={classes.redIcon} />
}

export default Checker