const config = {
  datetime: {
    format: {
      initial: 'DD.MM.YYYY',
      short: 'DD.MM.',
      long: 'DD.MM.YYYY HH:mm',
      long_short: 'DD.MM. HH:mm',
      rangeBegin: 'DD.MM',
      rangeEnd: 'DD.MM.YYYY',
    },
  },
}

export default config
