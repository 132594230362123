import {
  Avatar,
  Button,
  Grid, Paper, TextField,
  Typography
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { Form, Formik, useFormik } from "formik"
import { useSnackbar } from "notistack"
import { UserContext } from "providers/user.provider"
import { useCallback, useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { createFormPersonalInformationsSchema } from "../Settings@config"
import { settingsPartsStyles } from "../Settings@styles"

const useStyles = makeStyles(settingsPartsStyles)

const Profile = () => {
  const {
    loadUserData,
    userData,
    getFirstLetterOfUsername,
    updateProfileData,
    verifyEmailAddress,
  } = useContext(UserContext)

  // hooks
  const classes = useStyles()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  // personal informations form
  const personalFormik = useFormik({
    initialValues: {
      displayName: userData?.displayName || '',
      email: userData?.email || '',
      phoneNumber: userData?.phoneNumber || '',
    },
    validationSchema: createFormPersonalInformationsSchema(),
    onSubmit: () => handleChangePersonInformations(),
  })

  // effect
  useEffect(() => {
    loadUserData()
    // eslint-disable-next-line
  }, [])

  const handleChangePersonInformations = useCallback(() => {
    try {
      updateProfileData(personalFormik.values)
      loadUserData()
      enqueueSnackbar(
        t`Alert.Personal_Informations_Was_Changed`,
        { variant: 'info' }
      )
    } catch (err) {
      console.error(err)
    }
    // eslint-disable-next-line
  }, [
    updateProfileData,
    loadUserData,
    enqueueSnackbar,
    t
  ])

  const handleVerifyEmailAddress = useCallback(() => {
    try {
      verifyEmailAddress()
      enqueueSnackbar(
        t`Alert.Verify_Email_Was_Send`,
        { variant: 'info' }
      )
    } catch (err) {
      console.error(err)
    }
  }, [verifyEmailAddress, enqueueSnackbar, t])

  return (
    <div className={classes.profileSettingsRoot}>
      <Grid container spacing={4}>
        <Grid item md={4} xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="overline" gutterBottom>
              {t`Settings.Profile_Image`}
            </Typography>
            <div className={classes.avatarContent}>
              <Avatar
                alt="Remy Sharp"
                src="/static/images/avatar/1.jpg"
                className={classes.avatar}
              >
                {getFirstLetterOfUsername || ''}
              </Avatar>
            </div>
            <div className={classes.textConditions}>
              <Typography variant="caption" color="textSecondary" gutterBottom>
                {t`Settings.Upload_Image_Conditions`}
              </Typography>
            </div>
            <div className={classes.uploadButton}>
              <Button
                disabled
                variant="outlined"
                size="small"
                color="secondary"
              >
                {t`Global.Upload`}
              </Button>
            </div>
          </Paper>
        </Grid>

        <Grid item md={8} xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="overline" gutterBottom>
              {t`Settings.Personal_Informations`}
            </Typography>

            {!userData?.emailVerified && (
              <div className={classes.verificationAlert}>
                <Alert
                  severity="warning"
                  action={
                    <Button
                      onClick={handleVerifyEmailAddress}
                      color="inherit"
                      size="small"
                    >
                      {t`Settings.Verify_Email`}
                    </Button>
                  }
                >
                  {t`Settings.Email_Is_Not_Verified`}
                </Alert>
              </div>
            )}

            <div className={classes.personalForm}>
              <Formik
                initialValues={personalFormik.initialValues}
                onSubmit={() => personalFormik.handleSubmit()}
              >
                <Form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        color="primary"
                        variant="outlined"
                        margin="none"
                        id="displayName"
                        name="displayName"
                        type="text"
                        label={t`Settings.Name_Surname`}
                        value={personalFormik.values.displayName}
                        onChange={personalFormik.handleChange}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        disabled
                        fullWidth
                        required
                        color="primary"
                        variant="outlined"
                        margin="none"
                        id="email"
                        name="email"
                        type="text"
                        label={t`Settings.Email`}
                        value={personalFormik.values.email}
                        onChange={personalFormik.handleChange}
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        disabled
                        fullWidth
                        color="primary"
                        variant="outlined"
                        margin="none"
                        id="phoneNumber"
                        name="phoneNumber"
                        type="text"
                        label={t`Settings.Phone`}
                        value={personalFormik.values.phoneNumber}
                        onChange={personalFormik.handleChange}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={classes.personalFormSendButton}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        type="submit"
                      >
                        {t`Global.Save_Changes`}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              </Formik>
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default Profile