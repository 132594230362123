import * as yup from 'yup'
import cs from 'yup-locale-cs'

export default function instance(language: string) {
  // set cs locales if active language is CS
  if (language === 'cs') {
    yup.setLocale(cs)
  }

  return yup
}
