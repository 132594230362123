import { User } from '../../types/user.types'

export const users: User[] = [
  {
    id: '1',
    fullName: 'Lukáš Kaleta',
    profession: 'CEO, Developer, Designer',
    initials: 'LK',
    createdAt: '1625204371',
    lastLoginAt: '1626269311',
    verified: true,
    photoUrl: null,
    roles: ['owner', 'grand administrator'],
    coverUrl: 'https://bit.ly/3eAMwYT',
  },
  {
    id: '2',
    fullName: 'Karel Kaleta',
    initials: 'KK',
    profession: 'CTO, Developer',
    createdAt: '1625563711',
    lastLoginAt: '1625405311',
    verified: false,
    photoUrl: null,
    roles: ['grand administrator'],
    coverUrl: 'https://bit.ly/2U8ChDX',
  },
  {
    id: '3',
    fullName: 'Lada Šmiřáková',
    initials: 'LŠ',
    profession: 'Copyrighter',
    createdAt: '1625909311',
    lastLoginAt: '1626096511',
    verified: false,
    photoUrl: null,
    roles: ['editor'],
    coverUrl: 'https://bit.ly/3wzuKeF',
  },
  {
    id: '4',
    fullName: 'Jan Grundza',
    initials: 'JG',
    profession: 'Obchodní zástupce',
    createdAt: '1625650111',
    lastLoginAt: '1626254911',
    roles: ['administrator'],
    verified: false,
    photoUrl: null,
    coverUrl: 'https://bit.ly/2U2HWvq',
  },
]
