import { IconButton, Badge, Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import NotificationsIcon from '@material-ui/icons/Notifications'

import { notificationsStyles } from './Notifications@styles'
import { useContext, useState } from 'react'
import { NotificationsContext } from '../../providers/nofitications.provider'
import { NotificationsStateTypes } from './Notifications@types'
import { Notification } from './Notifications@parts'
import { NotificationsModel } from '../../models/notifications.model'

const useStyles = makeStyles(notificationsStyles)

const Notifications = () => {
  // state
  const [state, setState] = useState<NotificationsStateTypes>({
    menu: null,
  })

  // hooks
  const classes = useStyles()

  // context
  const { notificationsCount, notifications } = useContext(NotificationsContext)

  const handleShowMenu = (e: any) => {
    setState((prevState) => ({
      ...prevState,
      menu: e.currentTarget,
    }))
  }

  const handleCloseMenu = () => {
    setState((prevState) => ({
      ...prevState,
      menu: null,
    }))
  }

  return (
    <div className={classes.root}>
      <IconButton onClick={(e) => handleShowMenu(e)}>
        <Badge badgeContent={notificationsCount} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Menu
        id="notifications"
        className={classes.menu}
        anchorEl={state.menu}
        keepMounted
        open={Boolean(state.menu)}
        onClose={handleCloseMenu}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {notifications.map((notification: NotificationsModel) => (
          <MenuItem key={notification.id}>
            <Notification
              title={notification.title}
              date={notification.created_at}
              type={notification.type}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

export default Notifications
