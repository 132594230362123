import { createContext, ReactNode, useCallback, useState } from 'react';

interface IDialogProviderState {
  dialog: boolean
}

interface IDialogProviderProps {
  children: ReactNode
}

interface Context extends IDialogProviderState {
  handleDialogAction: () => void
}

export const DialogContext = createContext<Context>(null as any)

const DialogProvider = (props: IDialogProviderProps) => {
  const { children } = props
  const [state, setState] = useState<IDialogProviderState>({
    dialog: false
  })

  const handleDialogAction = useCallback(() => {
    setState((prevState) => ({
      ...state,
      dialog: !prevState.dialog
    }))
  }, [state])

  return (
    <DialogContext.Provider
      value={{
        dialog: state.dialog,
        handleDialogAction
      }}
    >
      {children}
    </DialogContext.Provider>
  )
}

export default DialogProvider 