import { initYup } from '../../plugins/yup'
import i18n from '../../services/i18n'
import t from 'i18next'

export const createFormUserFormChangePasswordSchema = () => {
  const yup = initYup(i18n.language)

  return yup.object({
    newPassword: yup
      .string()
      .required()
      .min(6)
      .label(t.t`User.New_Password`),
    confirmNewPassword: yup
      .string()
      .required()
      .min(6)
      .label(t.t`User.Confirm_New_Password`),
  })
}
