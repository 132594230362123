import { ThemeColor } from '../styles/Styles@types'

export const Colors: ThemeColor = {
  primary: {
    main: '#00AB55',
    light: '#007b55',
    dark: '#56d97c',
  },
  secondary: {
    main: '#279cca',
    light: '#279cca',
    dark: '#279cca',
  },
  red: {
    main: '#F44336',
  },
  green: {
    main: '#4CAF50',
  },
  orange: {
    main: '#FF9800',
  },
  black: {
    main: '#333333',
    light: '#212b36',
    dark: '#161b23',
  },
  white: {
    main: '#FFFFFF',
    dark: '#f2f2f2',
  },
  grey: {
    main: '#637381',
    light: '#212236',
    dark: '#151b23',
  },
}
