import { createContext, ReactNode, useState } from 'react';

import firebase from '../remote/Firebase'
import { Collections, Documents, SubCollections } from '../remote/Collections';
import { IFoodMenuCategory, IFood } from '../types/food-menu.types';

interface IFoodMenuProviderState {
  isLoading: boolean
  categories: IFoodMenuCategory[] | []
  detailOfFood: IFood | undefined
  foods: IFood[] | []
}

interface IContext extends IFoodMenuProviderState {
  getCategories: () => void
  getFoodsOfCategory: (id: string) => void
  getDetailOfFood: (categoryId: string, foodId: string) => void
  insertNewFoodToCategory: (data: any, id: string) => void
  updateFoodFromCategory: (categoryId: string, foodId: string, data: any) => void
  uploadImageToFoodFromCategory: (categoryId: string, foodId: string, file: File[]) => void
  removeFoodFromCategory: (categoryId: string, foodId: string) => void
  removeCategory: (categoryId: string) => void
}

interface IFoodMenuProviderProps {
  children: ReactNode
}

export const FoodMenuContext = createContext<IContext>(null as any)

const FoodMenuProvider = (props: IFoodMenuProviderProps) => {
  // state
  const [state, setState] = useState<IFoodMenuProviderState>({
    isLoading: true,
    categories: [],
    detailOfFood: undefined,
    foods: []
  })
  const { isLoading, categories, detailOfFood, foods } = state

  // props
  const { children } = props

  // remote settings
  const db = firebase.firestore()
  const storage = firebase.storage()
  const collection = Collections.Food_Menu
  const documents = Documents.Categories
  const subCollection = SubCollections.Data

  const cleaningState = () => {
    setState({
      isLoading: true,
      categories: [],
      detailOfFood: undefined,
      foods: []
    })
  }

  const getCategories = () => {
    cleaningState()

    return db
      .collection(collection)
      .doc(documents)
      .collection(subCollection)
      .onSnapshot((s) => {
        const listItems = s.docs.map((doc: any) => ({
          id: doc.id,
          ...doc.data()
        }))
        setState((prevState) => ({
          ...prevState,
          categories: listItems,
          isLoading: false
        }))
      })
  }

  const getFoodsOfCategory = (id: string) => {
    cleaningState()

    return db
      .collection(collection)
      .doc(documents)
      .collection(subCollection)
      .doc(id)
      .collection(subCollection)
      .onSnapshot((s) => {
        const listItems = s.docs.map((doc: any) => ({
          id: doc.id,
          ...doc.data()
        }))
        setState((prevState) => ({
          ...prevState,
          foods: listItems,
          isLoading: false
        }))
      })
  }

  const getDetailOfFood = (categoryId: string, foodId: string) => {
    cleaningState()

    return db
      .collection(collection)
      .doc(documents)
      .collection(subCollection)
      .doc(categoryId)
      .collection(subCollection)
      .doc(foodId)
      .get()
      .then((s) => {
        const item: any = s.data()
        setState((prevState) => ({
          ...prevState,
          detailOfFood: item,
          isLoading: false
        }))
      })
  }

  const insertNewFoodToCategory = (data: any, id: string) => {
    return db
      .collection(collection)
      .doc(documents)
      .collection(subCollection)
      .doc(id)
      .collection(subCollection)
      .add(data)
  }

  const updateFoodFromCategory = (categoryId: string, foodId: string, data: any) => {
    return db
      .collection(collection)
      .doc(documents)
      .collection(subCollection)
      .doc(categoryId)
      .collection(subCollection)
      .doc(foodId)
      .update(data)
  }

  const uploadImageToFoodFromCategory = async (categoryId: string, foodId: string, file: File[]) => {
    const storageRef = storage.ref()
    const fileRef = storageRef.child(file[0].name)
    await fileRef.put(file[0])

    return db
      .collection(collection)
      .doc(documents)
      .collection(subCollection)
      .doc(categoryId)
      .collection(subCollection)
      .doc(foodId)
      .update({
        images: firebase.firestore.FieldValue.arrayUnion({
          name: file[0].name,
          url: await fileRef.getDownloadURL()
        })
      })
  }

  const removeFoodFromCategory = (categoryId: string, foodId: string) => {
    return db
      .collection(collection)
      .doc(documents)
      .collection(subCollection)
      .doc(categoryId)
      .collection(subCollection)
      .doc(foodId)
      .delete()
  }

  const removeCategory = (categoryId: string) => {
    return db
      .collection(collection)
      .doc(documents)
      .collection(subCollection)
      .doc(categoryId)
      .delete()
  }

  return (
    <FoodMenuContext.Provider
      value={{
        isLoading,
        categories,
        detailOfFood,
        foods,
        getCategories,
        getFoodsOfCategory,
        getDetailOfFood,
        insertNewFoodToCategory,
        updateFoodFromCategory,
        uploadImageToFoodFromCategory,
        removeFoodFromCategory,
        removeCategory
      }}
    >
      {children}
    </FoodMenuContext.Provider>
  )
}

export default FoodMenuProvider