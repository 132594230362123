import { makeStyles } from '@material-ui/styles'
import MaterialTable, { MTableToolbar } from 'material-table'
import { useTranslation } from 'react-i18next'
import SettingsIcon from '@material-ui/icons/Settings'
import CheckIcon from '@material-ui/icons/Check'
import { TableStyles } from './Table@styles'
import { TablePropsTypes, TableToolbarStateTypes } from './Table@types'
import {
  Checkbox,
  IconButton,
  FormGroup,
  FormControlLabel,
  Typography,
  Card,
  Tooltip,
} from '@material-ui/core'
import { ChangeEvent, useState } from 'react'

const useStyles = makeStyles(TableStyles)

const Table = (props: TablePropsTypes) => {
  // state
  const [state, setState] = useState<TableToolbarStateTypes>({
    settingsMenu: false,
    filtering: false,
    grouping: false,
  })

  // hooks
  const classes = useStyles()
  const { t } = useTranslation()

  const handleActionsSettingsSection = () => {
    setState((prevState) => ({
      ...prevState,
      settingsMenu: !state.settingsMenu,
    }))
  }

  const handleChangeSettings = (event: ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }))
  }

  return (
    <div className={classes.root}>
      <MaterialTable
        components={{
          Toolbar: (props) => (
            <div>
              <div className={classes.toolbar}>
                <div className={classes.basic}>
                  <MTableToolbar {...props} />
                </div>
                <div className={classes.suplement}>
                  <Tooltip
                    title={`${
                      state.settingsMenu
                        ? t`Global.Save`
                        : t`Table.Table_Settings`
                    }`}
                  >
                    <IconButton
                      aria-controls="table-settings-menu"
                      onClick={handleActionsSettingsSection}
                    >
                      {state.settingsMenu ? (
                        <CheckIcon color="primary" />
                      ) : (
                        <SettingsIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
              {state.settingsMenu && (
                <div className={classes.tableSettingsContent}>
                  <Card
                    variant="outlined"
                    className={classes.tableSettingsContentCard}
                  >
                    <Typography variant="overline" display="block">
                      {t`Table.Table_Settings`}
                    </Typography>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.filtering}
                            onChange={handleChangeSettings}
                            name="filtering"
                            color="primary"
                            size="small"
                          />
                        }
                        label={t`Table.Filtering`}
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.grouping}
                            onChange={handleChangeSettings}
                            name="grouping"
                            color="primary"
                            size="small"
                          />
                        }
                        label={t`Table.Grouping`}
                      />
                    </FormGroup>
                  </Card>
                </div>
              )}
            </div>
          ),
        }}
        style={{ width: '100%' }}
        {...props}
        options={{
          filtering: state.filtering,
          grouping: state.grouping,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: t`Table.Empty_Data_Source_Message`,
            addTooltip: t`Table.Add_Tooltip`,
            deleteTooltip: t`Table.Delete_Tooltip`,
            editTooltip: t`Table.Edit_Tooltip`,
            filterRow: {
              filterTooltip: t`Table.Filter_Tooltip`,
            },
            editRow: {
              deleteText: t`Table.Delete_Text`,
              cancelTooltip: t`Table.Cancel_Tooltip`,
              saveTooltip: t`Table.Save_Tooltip`,
            },
          },
          grouping: {
            placeholder: t`Table.Placeholder`,
            groupedBy: t`Table.Grouped_By`,
          },
          header: {
            actions: t`Table.Actions`,
          },
          pagination: {
            labelDisplayedRows: t`Table.Label_Displayed_Rows`,
            labelRowsSelect: t`Table.Label_Rows_Selected`,
            labelRowsPerPage: t`Table.Label_Rows_Per_Page`,
            firstAriaLabel: t`Table.First_Aria_Label`,
            firstTooltip: t`Table.First_Tooltip`,
            previousAriaLabel: t`Table.Previous_Aria_Label`,
            previousTooltip: t`Table.Previous_Tooltip`,
            nextAriaLabel: t`Table.Next_Aria_Label`,
            nextTooltip: t`Table.Next_Tooltip`,
            lastAriaLabel: t`Table.Last_Aria_Label`,
            lastTooltip: t`Table.Last_Tooltip`,
          },
          toolbar: {
            addRemoveColumns: t`Table.Add_Remote_Columns`,
            nRowsSelected: t`Table.N_Rows_Selected`,
            showColumnsTitle: t`Table.Show_Columns_Title`,
            showColumnsAriaLabel: t`Table.Show_Columns_Aria_Label`,
            exportTitle: t`Table.Export_Title`,
            exportAriaLabel: t`Table.Export_AriaLabel`,
            searchTooltip: t`Table.Search_Tooltip`,
            searchPlaceholder: t`Table.Search_Placeholder`,
          },
        }}
      />
    </div>
  )
}

export default Table
