import {
  Avatar,
  CssBaseline,
  Grid, Link, Paper, TextField, Typography
} from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { Alert } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { Form, Formik, useFormik } from 'formik'
import { useSnackbar } from 'notistack'
import { useCallback, useContext, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button } from '../../components/Button'
import { Loading } from '../../components/Loading'
import { AuthContext } from '../../providers/auth.provider'
import {
  createFormForgotPasswordSchema, formLoginSchema
} from './Login@config'
import { LoginStyles } from './Login@styles'

const useStyles = makeStyles(LoginStyles)

const Login = () => {
  const [forgetPassword, setForgetPassword] = useState(false)

  // hooks
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const { register, handleSubmit } = useForm({
    resolver: yupResolver(formLoginSchema())
  })

  const formikForgotPassword = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: createFormForgotPasswordSchema(),
    onSubmit: () => handleSendEmail(),
  })

  // context
  const { login, loading, sendPasswordResetEmail } = useContext(AuthContext)

  const handleLogin = useCallback((data) => {
    try {
      login(data)
    } catch (err) {
      // console.error(err)
      enqueueSnackbar(
        t`Alert.Logged_In_Error`,
        { variant: 'error' }
      )
    }
  }, [enqueueSnackbar, t, login])

  const handleSendEmail = useCallback(() => {
    try {
      sendPasswordResetEmail(formikForgotPassword.values)
      setForgetPassword(false)
      enqueueSnackbar(
        t`Alert.Email_Was_Send`,
        { variant: 'info' }
      )
    } catch (err) {
      console.error(err)
    }
    // eslint-disable-next-line
  }, [
    sendPasswordResetEmail,
    setForgetPassword,
    enqueueSnackbar,
    t,
  ])

  if (loading) {
    return (
      <div className={classes.root}>
        <Loading description={t`Login.Logged_In`} />
      </div>
    )
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>

          <Typography component="h1" variant="h5">
            {forgetPassword
              ? t`Login.Forgot_Password_Header`
              : t`Login.Sign_In_To_Administration`}
          </Typography>

          {forgetPassword ? (
            <Formik
              initialValues={formikForgotPassword.initialValues}
              onSubmit={() => formikForgotPassword.handleSubmit()}
            >
              <Form style={{ width: '100%' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      required
                      variant="outlined"
                      margin="normal"
                      id="email"
                      name="email"
                      label={t`Login.Email_Address`}
                      value={formikForgotPassword.values.email}
                      onChange={formikForgotPassword.handleChange}
                      autoComplete="username"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Alert severity="info">{t`Login.Forgot_Password_Description`}</Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      fullWidth
                    >
                      {t`Global.Send`}
                    </Button>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs />
                  <Grid item>
                    <Link
                      className={classes.link}
                      onClick={() => setForgetPassword(!forgetPassword)}
                      color="secondary"
                      variant="body2"
                    >
                      {t`Login.Back_To_Login`}
                    </Link>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
          ) : (
            <form
              style={{ width: '100%' }}
              onSubmit={handleSubmit(handleLogin)}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    variant="outlined"
                    margin="normal"
                    {...register('email')}
                    label={t`Login.Email_Address`}
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    required
                    type="password"
                    variant="outlined"
                    margin="normal"
                    autoComplete="password"
                    label={t`Login.Password`}
                    {...register('password')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    fullWidth
                  >
                    {t`Login.Sign_In`}
                  </Button>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs />
                <Grid item>
                  <Link
                    className={classes.link}
                    onClick={() => setForgetPassword(!forgetPassword)}
                    color="secondary"
                    variant="body2"
                  >
                    {t`Login.Forgot_Password`}
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        </div>
      </Grid>
    </Grid>
  )
}

export default Login
