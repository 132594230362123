import { createContext, ReactNode, useEffect, useState } from 'react'
import { ThemeProvider } from '@material-ui/styles'
import { themeDark, themeLight } from '../theme'
import localStorageNames from '../lib/local-storage/names'
import { Themes } from '../styles/Styles@types'
import { useTranslation } from 'react-i18next'

type Context = {
  navOpen: boolean
  setNavOpen: any
  theme: string
  setTheme: any
  setThemeMode: () => void
  getThemeMode: () => void
  setLanguage: (lang: string) => void
  getLanguage: string | null
  getSiderNavWidth: number
}

type AppProviderProps = {
  children: ReactNode
}

export const AppContext = createContext<Context>(null as any)

const AppProvider = (props: AppProviderProps) => {
  // state
  const [navOpen, setNavOpen] = useState<boolean>(true)
  const [theme, setTheme] = useState<string>(Themes.light)

  // props
  const { children } = props

  // hooks
  const { i18n } = useTranslation()

  // SIDE NAV
  const getSiderNavWidth = 270

  // THEME
  const getThemeMode = () => {
    const value = localStorage.getItem(localStorageNames.THEME)
    return value || theme
  }

  useEffect(() => {
    getThemeMode()
  })

  const setThemeMode = () => {
    if (theme === Themes.light) {
      setTheme(Themes.dark)
      localStorage.setItem(localStorageNames.THEME, Themes.dark)
    } else {
      setTheme(Themes.light)
      localStorage.setItem(localStorageNames.THEME, Themes.light)
    }
  }

  // LANGUAGE
  const getLanguage = localStorage.getItem('i18nextLng')

  const setLanguage = (lang: string) => {
    i18n.changeLanguage(lang)
  }

  const themeConfig = getThemeMode() === Themes.dark ? themeDark : themeLight

  return (
    <ThemeProvider theme={themeConfig}>
      <AppContext.Provider
        value={{
          navOpen,
          setNavOpen,
          theme,
          setTheme,
          setThemeMode,
          getThemeMode,
          setLanguage,
          getLanguage,
          getSiderNavWidth,
        }}
      >
        {children}
      </AppContext.Provider>
    </ThemeProvider>
  )
}

export default AppProvider
